import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as exreactjs from 'src/_data/exreactjs.json';
import * as exangular from 'src/_data/exangular.json';
import { UUID } from 'angular2-uuid';
import { Utils } from 'src/app/shared/utils';
import * as XLSX from 'xlsx';

import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiRowSelectionMode, GuiSelectedRow, GuiColumnAlign, GuiPaging, GuiPagingDisplay, GuiRowColoring, GuiRowSelection, GuiRowSelectionType } from '@generic-ui/ngx-grid';

declare let $: any;
import { CalendarOptions } from '@fullcalendar/angular';
//import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

import { FileUpload } from 'src/app/models/fileupload';
import { UploadFileService } from 'src/app/services/upload-file.service';

import { DataService } from '../services/data.service';

@Component({
  selector: 'exverif',
  templateUrl: './exverif.component.html',
  styleUrls: ['./exverif.component.css']
})
export class ExverifComponent implements OnInit {

  entity = 'exreactjs'
  isRootAdmin; isAdmin;

  listusers;
  loaded; user; username;
  listfiles: any[] = []; listfiles2: any[] = [];
  listprojects: any[] = [];
  tabadmin = 1;

  selectedFile;
  searchTerm = { name: '' };

  hrefCrypto; hrefSkills;

  arrayBuffer: any; arrExcelFields;
  file: File; exverif;

  listexverif;

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private uploadService: UploadFileService) {

  }

  ngOnInit() {

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + '' + this.user['lastname'];
      else if (this.user && this.user['name'] && this.user['name'] != '')
        this.username = this.user['name'];
      else
        this.username = this.user['email'];

      this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
      this.isRootAdmin = (this.user['email'] == 'admin@admin.ro') && this.user['type'] && this.user['type'] == 'vvv';
      if (this.isRootAdmin)
        this.isAdmin = true;
    }

    if (!this.user || !this.user['id']) {
      this.router.navigate(['/auth/login']);
      return;
    }

    this.dataService.getList(this.entity).subscribe((all) => {
      this.exverif = all;
    });

  }

  import() {
    this.listexverif = exreactjs['default'];
    this.listexverif.forEach(element => {
      this.dataService.create(this.entity, element).subscribe((all) => {
      });
    });
  }

  check(item) {
    item['check'] = true;
    this.dataService.update(this.entity, item).subscribe((all) => {
    });
  }
  uncheck(item) {
    item['check'] = false;
    this.dataService.update(this.entity, item).subscribe((all) => {
    });
  }
  select(item, poz) {
    item['ans'] = poz;
    this.dataService.update(this.entity, item).subscribe((all) => {
    });
  }

  setlevel(item, event) {
    item['level'] = event.target.value;
    this.dataService.update(this.entity, item).subscribe((all) => {
    });
  }

  getmulti(value) {

    if (!value || value == '')
      return [];

    return value.split(';')
  }

  readappexcel() {
    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];

      this.arrExcelFields = XLSX.utils.sheet_to_json(worksheet, { raw: true });


      //create fields
      for (var fkey in this.arrExcelFields) {

        let item = this.arrExcelFields[fkey];
        if (item) {

          // this.dataService.create(this.entity, item).subscribe((all) => {

          // });

          //     for (var fkey2 in item) {
          //         this.listfields.push({
          //             "id":this.guid(), 
          //             "name": fkey2,
          //             "type": "text",
          //             "checkbox1": true,
          //             "checkbox2": true
          //          });
          //     }
          //  break;

        }
      }
    }
    fileReader.readAsArrayBuffer(this.file);
  }

  saveexcel(list: any) {

    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(list);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Cmenzi');
    XLSX.writeFile(wb, 'comenzi_export_' + new Date().getTime() + '.xlsx');

  }

}
