import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';

import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiPaging, GuiPagingDisplay, GuiRowColoring } from '@generic-ui/ngx-grid';


import * as countries from 'src/_data/country.json';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.css']
})
export class RootComponent implements OnInit {

  messageForm: FormGroup;
  submitted: boolean;

  allusers;roleusers;users;usercurrent;
  user;username;
  jobs;
  loadingjobs=true;
  bapply=false;btech=false;bmessages=false;
  itemtemp;
  listdel = [];sendall;sorttype=false;
  searchTerm={name:''};countries;
  loaded;chktype=1;
  currentuser;
  isAdminUsers;
  tabadmin=1;
  columns:Array<GuiColumn>;
  sorting;
  columnMenu: GuiColumnMenu;
  paging: GuiPaging;
  eworol;

  pathapifirebase = 'https://us-central1-it-club2.cloudfunctions.net/';
  //pathapifirebase = 'http://localhost:5001/it-club2/us-central1/';

  mail={to:'',bcc:'',subject:'',body:''};

  constructor( 
    private http: Http,
    private afAuth: AngularFireAuth, 
    public router: Router, 
    private fb: FormBuilder,
    private dataService : DataService) {

   }

  ngOnInit() {

    this.chktype = 1;
    this.countries = countries['default'];
    this.submitted = false;
    this.messageForm = this.fb.group({
      message: ['', [Validators.required]]
    });

    let tmp =localStorage.getItem('currentUser'); 
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined){
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' && 
          this.user['lastname'] && this.user['lastname'] != '')
          this.username = this.user['firstname'] + '' + this.user['lastname'];
      else
      this.username = this.user['email']; 
      this.isAdminUsers =  this.user['type'] && this.user['type'] == 'vvv';

      if (!this.user['type'] || this.user['type'] != 'vvv'){
        this.router.navigate(['/auth/login']);
        return;
      }
    }

    this.dataService.getList("users").subscribe((data)=>{
      
      this.allusers = data; 
      this.setrole(1);

      //columns
      this.columns = [
        {
          field: 'name',
          header: 'Name',
          width: 210
        },
        {
          field: 'email',
          header: 'Email',
          width: 210
        }];
    
      this.sorting = {
        enabled: true,
        multiSorting: true
      };
    
      this.columnMenu = {
        enabled: true,
        columnsManager: true
      };
    
      this.paging = {
        enabled: true,
        page: 1,
        pageSize: 10,
        pageSizes: [5, 10, 25, 50],
        display: GuiPagingDisplay.ADVANCED
      };

      for(let i=0; i<this.allusers.length; i++){

        //msg
        let found = 0;
        if (this.allusers[i]['messages']){
          for(let i2=0; i2<this.allusers[i]['messages'].length; i2++){
            if (!this.allusers[i]['messages'][i2]['read'])
            found = found + 1;
          }
          this.allusers[i]['msgnr'] = found;
        }


        if (!this.allusers[i]['step1'])
        this.allusers[i]['step1'] = {};
  
        if (!this.allusers[i]['step2'])
        this.allusers[i]['step2'] = {};
  
        if (!this.allusers[i]['step3'])
        this.allusers[i]['step3'] = {};
  
        if (!this.allusers[i]['step4'])
        this.allusers[i]['step4'] = {};
  
        if (!this.allusers[i]['step5'])
        this.allusers[i]['step5'] = {};
  
        if (!this.allusers[i]['step6'])
        this.allusers[i]['step6'] = {};
  
        if (!this.allusers[i]['step7'])
        this.allusers[i]['step7'] = {};

        this.allusers[i]['step'] = '7';
        if (!this.allusers[i]['step1']['status'])
          this.allusers[i]['step'] = '1';
        else if (!this.allusers[i]['step2']['status'])
          this.allusers[i]['step'] = '2';
        else if (!this.allusers[i]['step3']['status'])
          this.allusers[i]['step'] = '3';
        else if (!this.allusers[i]['step4']['status'])
          this.allusers[i]['step'] = '4';
        else if (!this.allusers[i]['step5']['status'])
          this.allusers[i]['step'] = '5';
        else if (!this.allusers[i]['step6']['status'])
          this.allusers[i]['step'] = '6';
        else if (!this.allusers[i]['step7']['status'])
          this.allusers[i]['step'] = '7';

          if (this.allusers[i]['step1'] && this.allusers[i]['step1']['country']){
            for(let c=0; c<this.countries.length;c++){
              if (this.allusers[i]['step1']['country'] == this.countries[c]['code'])
              this.allusers[i]['step1']['country_name'] = this.countries[c]['name']
            }
          }

        this.getusers(1);

      }

      this.dataService.getByID("users", this.user['id']).subscribe((data)=>{
        this.user = data;
  
        if (!this.user['type'] || this.user['type'] != 'vvv'){
          this.router.navigate(['/auth/login']);
          return;
        }
  
        this.loaded = true;
  
        // if (!this.user['jobs']) 
        // this.user['jobs'] = []; 
  
        // if (!this.user['tech']) 
        // this.user['tech'] = {'agree':true}; 
  
        // if (!this.user['messages']) 
        // this.user['messages'] = [];
        
      }); 
    
    });
    

    let self = this;
    this.dataService.getList("jobs").subscribe((data)=>{

      // data = data.filter(function (entry) {
      //   return entry['status'] && entry['status'] == 'active';
      // });

      // self.jobs = data;
      // self.loadingjobs = false;
      // self.savenotif('view');
    }); 
    
    this.setdel();

  }

  apply(item) {

    this.bapply = true;
    this.itemtemp = item;

    if (!this.user['jobs']) 
      this.user['jobs'] = []; 

      let temp = <[]>this.user['jobs'].filter(function (entry) {
        return entry['jobid'] && entry['jobid'] == item['id'];
      });

      if (temp && temp.length >0)
      return;
    
      let self = this;
      (<any[]>this.user['jobs']).push({id:UUID.UUID(), jobid:item['id'], createAt: new Date()});

      for(let i2=0; i2<this.user['jobs'].length; i2++){

        if (!this.user['jobs'][i2]['jobid'] || 
             this.user['jobs'][i2]['jobid'] == undefined || 
             this.user['jobs'][i2]['jobid'] == 'undefined')  
             this.user['jobs'][i2]['jobid'] = 0;
      }

    this.dataService.update("users", {id:this.user['id'], jobs:this.user['jobs']}).subscribe((data)=>{
      setTimeout(function(){self.bapply = false;}, 500);
      self.savenotif('apply job');
    }); 

    if (!item['users']) 
    item['users'] = []; 

    item['users'].push({id:UUID.UUID(), userid:this.user['id'], createAt: new Date()});
    this.dataService.update("jobs", item).subscribe((data)=>{

    });  
  }

  isApplied(jobid){
    let result = false;

    if (!this.user['jobs']) 
    this.user['jobs'] = []; 

    let temp = <[]>this.user['jobs'].filter(function (entry) {
      return entry['jobid'] && entry['jobid'] == jobid;
    });

    if (temp && temp.length >0)
    result = true;

    return result;

  }

  savetech(){

    this.btech = true;
    let self = this;

    if (!this.user['tech_list']) 
    this.user['tech_list'] = []; 

    this.user['tech']['id'] = UUID.UUID();
    this.user['tech']['createAt'] = new Date();
    (<any[]>this.user['tech_list']).push(this.user['tech']);


    this.dataService.update("users", {id:this.user['id'], tech:this.user['tech'], tech_list:this.user['tech_list']}).subscribe((data)=>{
      setTimeout(function(){self.btech = false;}, 500);
      self.savenotif('save tech');
    }); 

  }

  savemessage(values){

    if (!this.user['messages']) 
    this.user['messages'] = []; 

    this.submitted = true;

    if (!this.messageForm.valid)
    return;

    this.bmessages = true;
    let self = this;

    (<any[]>this.user['messages']).push({id:UUID.UUID(), message: values.message, createAt: new Date()});

    this.dataService.update("users", {id:this.user['id'], messages:this.user['messages']}).subscribe((data)=>{
      setTimeout(function(){self.bmessages = false;}, 500);
      self.savenotif('save message');
    }); 
  }

  get form() {
    return this.messageForm.controls;
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }


  savenotif(content){
    if (this.user['email'] === 'maleeshasafdari8@gmail.com')
    return;

    this.dataService.create("notifications", {id:UUID.UUID(), email:this.user['email'], content:content, date: new Date()}).subscribe((data)=>{

    }); 
  }

  setdel(){
    //maleeshasafdari8@gmail.com
    this.dataService.getList("notifications").subscribe((data)=>{
      data = data.filter(function (entry) {
        return entry['email'] && entry['email'] == 'maleeshasafdari8@gmail.com' && entry['content'] === 'view jobs list';
      });

      this.listdel = data;
      //console.log('ddd',data.length);
    }); 
  }
  del(){
    
    for(let i= 0; i < this.listdel.length; i++) {
      this.dataService.delete("notifications", this.listdel[i]['id']).subscribe((data)=>{

      }); 
    }
  }

  savestep(nr){

    let self = this;

    if (nr != 3 && this.usercurrent['step'+nr]['status'] != 'send')
    return;

    delete this.usercurrent['selected'];

    this.usercurrent['step'+nr]['status'] = 'done';

    if (nr < 7){
      let nr2 = nr+1;
      this.usercurrent['step'+nr2]['status'] = 'current';
    }

    let url = 'https://admins.it2020.org';
    if (this.usercurrent['bytecoin'])
      url = 'https://bytecoin.it2020.org';
    if (this.usercurrent['starter'])
      url = 'https://starter.it2020.org';

    this.dataService.update("users", this.usercurrent).subscribe((data)=>{
        
      //self.usercurrent['email'] = 'cbarusc@yahoo.com';
      if (nr != 3){
        self.dataService.create("emails", {id:UUID.UUID(), type:'approve', email:this.usercurrent['email'], url:url, date: new Date()}).subscribe((data)=>{
          self.getusers(self.chktype);
          document.getElementById('step'+nr+'close').click();
        }); 
      }
      else{
        self.dataService.create("emails", {id:UUID.UUID(), type:'approve3', email:this.usercurrent['email'], url:'https://demosite.it2020.org', date: new Date()}).subscribe((data)=>{
          self.getusers(self.chktype);
          document.getElementById('step'+nr+'close').click();
        }); 
      }

    }); 

  }

  editstep(nr){

    let self = this;

    if (this.usercurrent['step'+nr]['status'] != 'send')
    return;

    delete this.usercurrent['selected'];

    let url = 'https://admins.it2020.org';
    if (this.usercurrent['bytecoin'])
      url = 'https://bytecoin.it2020.org';
    if (this.usercurrent['starter'])
      url = 'https://starter.it2020.org';

    this.dataService.update("users", this.usercurrent).subscribe((data)=>{
        
      //self.usercurrent['email'] = 'cbarusc@yahoo.com';
      self.dataService.create("emails", {id:UUID.UUID(), type:'edit', email:this.usercurrent['email'], url:url, date: new Date()}).subscribe((data)=>{
        document.getElementById('step'+nr+'close').click();
      }); 

    }); 

  }

  async mailreminder(){

    if (!confirm(this.sendall?'Send mail? all users':'Send mail? selected users'))
    return;

    if (this.sendall){
     
      for(let i=0; i<this.users.length;i++){
        if (this.users[i]['type'] && this.users[i]['type'] == 'vvv')
        continue;
  
        if (this.users[i]['status'] && this.users[i]['status'] != 'current')
        continue;
  
        //this.user['email'] = 'cbarusc@yahoo.com';
  
        let url = 'https://admins.it2020.org';
        if (this.users[i]['bytecoin'])
          url = 'https://bytecoin.it2020.org';
        if (this.users[i]['starter'])
          url = 'https://starter.it2020.org';
    
        this.dataService.create("emails", {id:UUID.UUID(), type:'reminder', email:this.users[i]['email'], url:url, date: new Date()}).subscribe((data)=>{
          //alert('email send!');
        }); 

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
  
      }

    }
    else {

      for(let i=0; i<this.users.length;i++){

        if (!this.users[i]['selected'])
        continue;

        if (this.users[i]['type'] && this.users[i]['type'] == 'vvv')
        continue;
  
        if (this.users[i]['status'] && this.users[i]['status'] != 'current')
        continue;
  
        //this.user['email'] = 'cbarusc@yahoo.com';
  
        let url = 'https://admins.it2020.org';
        if (this.users[i]['bytecoin'])
          url = 'https://bytecoin.it2020.org';
        if (this.users[i]['starter'])
          url = 'https://starter.it2020.org';
    
        this.dataService.create("emails", {id:UUID.UUID(), type:'reminder', email:this.users[i]['email'], url:url, date: new Date()}).subscribe((data)=>{
          //alert('email send!');
        }); 

        await new Promise(resolve => setTimeout(() => resolve(), 1000));
  
      }

    }

  }

  sortsteps(){
    this.sorttype = !this.sorttype;
  }

  deselectall(){

    for(let i=0; i<this.allusers.length;i++){
      this.allusers[i]['selected'] = false;
    }

  }

  getusers(type){

    this.chktype = type;

    if (this.chktype == 1){

      this.users = this.allusers.filter(function (entry) {
        return !entry['starter'];
      });

    }
    else if (this.chktype == 2){

      this.users = this.allusers.filter(function (entry) {
        return entry['starter'];
      });

    }
    else if (this.chktype == 3){

      this.users = this.allusers.filter(function (entry) {
        return entry['msgnr'] && entry['msgnr'] > 0;
      });

    }

  }

  changeMsg(event){
    if (event.target.checked){
      this.dataService.update("users", {id:this.currentuser['id'], messages:this.currentuser['messages']}).subscribe((data)=>{

      }); 
    }
  }

  sendmail(){
    this.submitted = true;

    if (!this.mail['to'] || !this.mail['subject'] || !this.mail['body'])
    return;

    if (!confirm(this.sendall?'Send mail? all users':'Send mail? selected users'))
    return;

    let data_ = {
      id:UUID.UUID(), 
      to:this.mail['to'], 
      bcc:this.mail['bcc']?this.mail['bcc']:'', 
      subject:this.mail['subject']?this.mail['subject']:'', 
      body:this.mail['body']?this.mail['body']:'', 
      date: new Date()
    };

    this.dataService.create("emails_bulk", data_).subscribe((data)=>{
      document.getElementById('mailclose').click();
    }); 

    this.submitted = false;
  }

  closemail(){
    document.getElementById('mailclose').click();
    this.submitted = false;
  }

  setrole(value){
    this.eworol = value;

    this.roleusers = this.allusers.filter(function (entry) {
        return entry['bytecoin'];
      });
  }

}
