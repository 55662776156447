import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'fb-auth';

  hrefDevs=false;hrefProjects=false;hrefFree=false;

  constructor( 
    public router: Router) {

   }

  ngOnInit() {
    // this.hrefDevs= window.location.href.toLowerCase().indexOf('devs.') >=0;
    // this.hrefProjects= window.location.href.toLowerCase().indexOf('projects.') >=0;
    // this.hrefFree= window.location.href.toLowerCase().indexOf('free.') >=0;

    // if(this.hrefProjects)
    // this.router.navigate(['/projectslist']);
    // if(this.hrefDevs)
    // this.router.navigate(['/devslist']);
    // if(this.hrefFree)
    // this.router.navigate(['/courses']);
  }


}
