import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterb',
    pure: false
})
export class FilterBPipe implements PipeTransform {
    transform(items: any[], field : string, value : boolean): any[] {  
      if (!items) return [];
      return items.filter(it => 
        (it[field] && it[field] == value) || (!it[field] && !value));
    }
}