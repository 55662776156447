import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "sort"
})
export class SortPipe  implements PipeTransform {
    transform(array: any[], field: string, type: string): any[] {
        array.sort((a: any, b: any) => {
            if (!a[field] && a[field] != 0) {
                return -1;
            } else
            if (!b[field] && b[field] != 0) {
                return 1;
            } else {
            if (!type || type == 'ASC'){    
                if (a[field] < b[field]) {
                    return -1;
                } else if (a[field] > b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
            if (type && type == 'DESC'){    
                if (a[field] > b[field]) {
                    return -1;
                } else if (a[field] < b[field]) {
                    return 1;
                } else {
                    return 0;
                }
            }
            }
        });
        return array;
    }
}