import { Component, OnInit, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import * as countries from 'src/_data/country.json';
import * as listtech from 'src/_data/tech.json';
import { UUID } from 'angular2-uuid';
import { Utils } from 'src/app/shared/utils';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiRowSelectionMode, GuiSelectedRow, GuiColumnAlign, GuiPaging, GuiPagingDisplay, GuiRowColoring, GuiRowSelection, GuiRowSelectionType } from '@generic-ui/ngx-grid';

declare let $: any;
import { CalendarOptions } from '@fullcalendar/angular';
//import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

import { DataService } from '../services/data.service';

@Component({
  selector: 'learn',
  templateUrl: './learn.component.html',
  styleUrls: ['./learn.component.scss']
})
export class LearnComponent implements OnInit {

  //options: FullCalendarOptions;
  //events: EventObject[];

  hrefCrypto; hrefSkills;
  
  startCourse;
  isAdminUsers; isAdminAaa;
  devsalign = 1;

  selectedDev; selectedExam;
  loaded; submitted; submittedgen;
  allusers; user; username; selectedRow; message;

  submittedcvlink; linkcv;

  msgcopy;

  config = {
    displayKey: "name"
  }

  arrayBuffer:any;arrExcelFields;
  file:File;

  columnsDevs = [];
  sorting;
  columnMenu: GuiColumnMenu;
  paging: GuiPaging;

  dev = {}; selecteDev; listdevs = []; listdevs2 = [];
  listalldevs = []; listallevents = [];
  tabadmin = 1;

  course = {};  submittedcourse;
  question = {}; submittedquestion;

  genLinkID; genLinkCVID;

  eventsAll = []; events = []; arg; item;
  calendarOptions: CalendarOptions;

  listtech = []; listexams = []; listexams2 = []; startExam;

  listcourses = [];listcourses2 = [];

  searchTerm = { name: '' };
  rowSelection: boolean | GuiRowSelection = {
    enabled: true,
    //type: GuiRowSelectionType.CHECKBOX
    //mode: GuiRowSelectionMode.MULTIPLE
  };

  dropdownSettings = {};

  mentors;

  listBusiness = [
    {
      'name':'Web developer (we can help you)',
      'url':'https://en.wikipedia.org/wiki/Web_developer',
      'tech':[
        {
          'name':'JavaScript'
        },
        {
          'name':'Angular'
        },
        {
          'name':'ReactJs'
        },
        {
          'name':'VueJs'
        },
        {
          'name':'TypeScript'
        },
        {
          'name':'Java'
        },
        {
          'name':'Python'
        },
        {
          'name':'Php'
        },
        {
          'name':'C#'
        },
        {
          'name':'Ruby'
        }
      ]
    },
    {
      'name':'Game developer',
      'url':'https://en.wikipedia.org/wiki/Game_developer',
      'tech':[
        {
          'name':'Java'
        },
        {
          'name':'C++'
        },
        {
          'name':'Python'
        },
        {
          'name':'JavaScript'
        },
        {
          'name':'Ruby'
        },
        {
          'name':'C'
        }
       ]
    },
    {
      'name':'Data analysis',
      'url':'https://en.wikipedia.org/wiki/Data_analysis',
      'tech':[
        {
          'name':'R'
        },
        {
          'name':'Matlab'
        },
        {
          'name':'Java'
        },
        {
          'name':'Python'
        }
       ]
    },
    {
      'name':'Desktop developer',
      'url':'https://www.quora.com/Can-I-become-a-desktop-developer-these-days-1',
      'tech':[
        {
          'name':'Java'
        },
        {
          'name':'C#'
        },
        {
          'name':'C++'
        }
       ]
    },
    {
      'name':'Embedded system program',
      'url':'https://en.wikipedia.org/wiki/Embedded_system',
      'tech':[
        {
          'name':'C'
        },
        {
          'name':'Python'
        },
        {
          'name':'C++'
        }
       ]
    },
    {
      'name':'Mobile apps developer',
      'url':'https://en.wikipedia.org/wiki/Mobile_app_development',
      'tech':[
        {
          'name':'Java'
        },
        {
          'name':'Objective-c'
        },
        {
          'name':'ReactNative'
        },
        {
          'name':'Ionic/Angular'
        },
        {
          'name':'Python'
        },
        {
          'name':'Dart'
        },
        {
          'name':'Swift'
        }
       ]
    }
    ]

    listGr1 = [
      {
        'name':'Programare Web - HTML,CSS,JavaScript/Angular/ReactJs/VueJs/.Net/Java/Php...',
        'url':'https://www.facebook.com/groups/itclub.ro/'
      }
    ]

    listGr2 = [
      {
        'name':'Primii pasi in programare - Do IT Now!',
        'url':'https://www.facebook.com/groups/2007985169450394/'
      },
      {
        'name':'IT-Programatori-incepatori-profesionisti',
        'url':'https://www.facebook.com/groups/234202386913591/'
      }
    ]

    listGr3 = [
      {
        'name':'Programare',
        'url':'https://www.facebook.com/groups/programarero/'
      },
      {
        'name':'Clubul Programatorilor',
        'url':'https://www.facebook.com/groups/374143346386307/'
      },
      {
        'name':'Programare',
        'url':'https://www.facebook.com/groups/programarero/'
      },
      {
        'name':'Clubul Programatorilor',
        'url':'https://www.facebook.com/groups/374143346386307/'
      },
      {
        'name':'Grupul programatorilor - începători și profesioniști',
        'url':'https://www.facebook.com/groups/programatoriro/'
      }
    ]

  constructor(
    private afAuth: AngularFireAuth,
    private route:ActivatedRoute, 
    public router: Router,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private dataService: DataService) {

  }

  ngOnInit() {

    this.route.params.subscribe(async params => {
      if (params['id']){

        localStorage.removeItem('currentUser')

        let userid = params['id'].substr(0, params['id'].length - 14);
        await this.dataService.getByID("users", userid).subscribe((data)=>{  

          if (data){
            localStorage.setItem('currentUser', JSON.stringify(data));
          }

          this.afterOnInit();
        })

      }
      else {
        this.afterOnInit();
      }
    })

  }

  afterOnInit(){
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.listtech = listtech['default'];

    this.columnsDevs = [
      {
        field: 'name',
        header: 'Name'
      },
      {
        field: 'job',
        header: 'Job',
        width: 200
      },
      {
        field: 'level',
        header: 'Level',
        width: 200
      }];

    this.sorting = {
      enabled: true,
      multiSorting: true
    };

    this.columnMenu = {
      enabled: false,
      columnsManager: false
    };

    this.paging = {
      enabled: true,
      page: 1,
      pageSize: 10,
      pageSizes: [5, 10, 25, 50],
      display: GuiPagingDisplay.ADVANCED
    };

    this.events = [
    ]

    //this.options = {
    //defaultDate: '2018-07-26',
    //editable: true
    //};

    this.calendarOptions = {
      headerToolbar: {
        left: 'dayGridYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        center: 'title',
        right: 'today,prevYear,prev,next,nextYear'
      },
      views: {
        dayGridYear: {
          type: 'dayGrid',
          buttonText: 'year',
          duration: { year: 1 }
        }
      },
      initialView: 'dayGridMonth',  //listWeek
      editable: false,
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventDragStart: this.handleEventDragStart.bind(this),
      eventDrop: this.handleEventDrop.bind(this),
      eventResizeStart: this.handleEventResizeStart.bind(this),
      eventResize: this.handleEventResize.bind(this),
      events: this.events
    }

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + ' ' + this.user['lastname'];
      else if (this.user && this.user['name'] && this.user['name'] != '')
        this.username = this.user['name'];
      else
        this.username = this.user['email'];

      this.isAdminUsers = this.user['type'] && this.user['type'] == 'vvv';
      this.isAdminAaa = this.user['email'] == 'a@a.ro'
    }

    if (!this.user || !this.user['id']) {
      this.router.navigate(['/auth/login']);
      return;
    }

    let tempSubscription = this.dataService.getList("exams").subscribe(data => {
      tempSubscription.unsubscribe();
      this.listexams = data;

      if (this.isAdminUsers) {
        this.getAllUser();
      }
      else {
        this.getOnlyUser();
      }

    });

    let tempSubscription2 = this.dataService.getList("courses").subscribe(data => {
      tempSubscription2.unsubscribe();
 
      this.listcourses = data.filter(function (entry) {
        return !entry['draft'];
      })

      for (var k in this.listcourses) {

        this.listcourses[k]['countlessons'] = 0;

        for (var key in this.listcourses[k].listcap) {
          var item = {};
          item = this.listcourses[k].listcap[key];
          if (item && item['lessons']) {
            this.listcourses[k]['countlessons'] = this.listcourses[k]['countlessons'] + item['lessons'].length;
          }
        }
      }

    });

    this.dataService.getList("mentors").subscribe(data => {
      this.mentors = data;
    });
  }


  getOnlyUser() {
    this.dataService.getByID("users", this.user['id']).subscribe((data) => {
      this.user = data;
      this.loaded = true;

      //if (this.user && this.user['email'] == 'a@a.ro'){
        if (document.getElementById('share-container'))
        document.getElementById('share-container').style.display = 'block'
        if (document.getElementById('imgmsg3'))
        document.getElementById('imgmsg3').style.display = 'block'
      //}

      if (!this.user || !this.user['id']) {
        this.router.navigate(['/auth/login']);
        return;
      }

      if (!this.user['devs'] || this.user['devs'].length == 0) {
        this.user['devs'] = [{ id: this.user['id'], name: this.username, email: this.user['email'] }];
        this.listdevs = this.user['devs'];
        this.savedevs();
      }

      if (!this.user['free'])
        this.user['free'] = [];

      if (!this.user['exams'])
        this.user['exams'] = [];

      if (!(this.user['job'] instanceof Array))
        this.user['job'] = [];

      this.selectedDev = this.user['devs'][0]['id'];
      this.listdevs = this.user['devs'];
      this.events = this.user['free'];

      for (let i = 0; i < this.events.length; i++) {
        for (let i2 = 0; i2 < this.listdevs.length; i2++) {

          if (this.events[i]['id'] == this.listdevs[i2]['id']) {
            this.events[i]['title'] = this.listdevs[i2]['name']

            this.events[i]['jobid'] = this.listdevs[i2]['job'];
            this.events[i]['color'] = this.getColor(this.listdevs[i2]['job']);
          }
        }
      }

      //exams
      for (let ie = 0; ie < this.user['exams'].length; ie++) {
        this.verifyexam(this.user['exams'][ie]);
      }

      this.calendarOptions.events = this.chkTech();

      this.filterDevs();
      this.filterExams();
    });
  }

  getAllUser() {

    if (!this.isAdminUsers)
      return;

    this.dataService.getList("users").subscribe((data) => {
      this.allusers = data;
      this.loaded = true;

      if (document.getElementById('share-container'))
      document.getElementById('share-container').style.display = 'block'
      if (document.getElementById('imgmsg3'))
      document.getElementById('imgmsg3').style.display = 'block'

      if (!this.user || !this.user['id']) {
        this.router.navigate(['/auth/login']);
        return;
      }

      if (!(this.user['job'] instanceof Array))
        this.user['job'] = [];

      this.listdevs = []; this.listalldevs = []; this.listallevents = [];
      for (let i = 0; i < this.allusers.length; i++) {

        if (!this.allusers[i]['devs'])
          this.allusers[i]['devs'] = [];

        if (!this.allusers[i]['free'])
          this.allusers[i]['free'] = [];

        if (!this.allusers[i]['exams'])
          this.allusers[i]['exams'] = [];


        if (!(this.allusers[i]['job'] instanceof Array))
          this.allusers[i]['job'] = [];

        if (this.allusers[i]['devs'] && this.allusers[i]['devs'].length > 0) {

          for (let i2 = 0; i2 < this.allusers[i]['devs'].length; i2++) {
            this.allusers[i]['devs'][i2]['company'] = this.allusers[i]['name'] + '/' + this.allusers[i]['email'];
          }

          this.listalldevs = [...this.listalldevs, ...this.allusers[i]['devs']];
        }


        if (this.allusers[i]['free'] && this.allusers[i]['free'].length > 0) {

          for (let i2 = 0; i2 < this.allusers[i]['free'].length; i2++) {
            this.allusers[i]['free'][i2]['company'] = this.allusers[i]['name'] + '/' + this.allusers[i]['email'];
          }

          this.listallevents = [...this.listallevents, ...this.allusers[i]['free']];
        }

        //exams
        for (let ie = 0; ie < this.allusers[i]['exams'].length; ie++) {
          this.verifyexam(this.allusers[i]['exams'][ie]);
        }

      }

      this.listdevs = this.listalldevs;
      this.events = this.listallevents;

      for (let i = 0; i < this.events.length; i++) {
        for (let i2 = 0; i2 < this.listdevs.length; i2++) {

          if (this.events[i]['id'] == this.listdevs[i2]['id']) {
            this.events[i]['title'] = this.listdevs[i2]['name']

            this.events[i]['jobid'] = this.listdevs[i2]['job'];
            this.events[i]['color'] = this.getColor(this.listdevs[i2]['job']);
          }
        }
      }


      this.calendarOptions.events = this.chkTech();

      this.filterDevs();
      this.filterExams();

    });
  }

  verifyexam(exam) {

    exam.countquestions2 = 0;

    //corect
    for (var key3 in exam.questions) {
      var item3 = exam.questions[key3];
      if (item3) {
        if (item3.valid == true)
          exam.countquestions2 = exam.countquestions2 + 1;
      }
    }

    //questions
    for (var keyex in this.listexams) {
      if (this.listexams[keyex] && this.listexams[keyex].listcap && this.listexams[keyex].listcap.length > 0) {
        exam.countquestionsall = this.listexams[keyex].listcap[0].questions.length;
      }
    }
  }

  getColor(job) {

    if (job == 'Angular')
      return '#2165BA'
    else if (job == 'ReactJs')
      return '#3BBEE8'
    else
      return 'gray'
  }

  filterDevs() {
    this.listcourses2 = [];

    if (!this.searchTerm || !this.searchTerm['name'])
      return this.listcourses2 = this.listcourses;

    for (let i = 0; i < this.listcourses.length; i++) {
      if (Utils.contain(this.listcourses[i]['name'], this.searchTerm['name']) ||
        Utils.contain(this.listcourses[i]['description'], this.searchTerm['name']))
        this.listcourses2.push(this.listcourses[i]);
    }


    return this.listcourses2;
  }

  filterExams() {
    this.listexams2 = [];

    if (!this.searchTerm || !this.searchTerm['name'])
      return this.listexams2 = this.user['exams'];

    for (let i = 0; i < this.user['exams'].length; i++) {
      if (Utils.contain(this.user['exams'][i]['name'], this.searchTerm['name']) ||
        Utils.contain(this.user['exams'][i]['resource'], this.searchTerm['name']))
        this.listexams2.push(this.user['exams'][i]);
    }


    return this.listdevs2;
  }

  clickTech() {
    this.calendarOptions.events = this.chkTech();
  }

  chkTech() {

    let result = [];
    let found = false;

    for (let i = 0; i < this.listtech.length; i++) {

      if (this.listtech[i]['checked']) {

        found = true;
        for (let i2 = 0; i2 < this.events.length; i2++) {
          if (Utils.contain(JSON.stringify(this.events[i2]['jobid']), this.listtech[i]['name']))
            result.push(this.events[i2]);
        }
      }
    }

    if (found)
      return result;
    else
      return this.events;
  }

  onSelectedRows(rows: Array<GuiSelectedRow>): void {
    if (!rows || rows.length == 0)
      return;

    this.selectedRow = rows;
  }

  editResource(id) {
    alert(id);
  }

  handleDateClick(arg) {
    this.arg = arg;
    this.item = { start: arg.dateStr, state: 'add' }

    $("#myModal").modal({
      backdrop: 'static',
      keyboard: false
    });
    // $(".modal-title").text("");
    // $(".modal-title").text("Add Event at : "+arg.dateStr);
  }

  handleEventClick(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.item = this.events[i];
        this.item['state'] = 'edit';
      }
    }

    $("#myModal").modal({
      backdrop: 'static',
      keyboard: false
    });
    // $(".modal-title").text("Title: "+arg.event.title);
  }

  handleEventDragStart(arg) {
  }

  handleEventDrop(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.events[i]['start'] = arg.event.start;
        this.events[i]['end'] = arg.event.end;

        this.calendarOptions.events = this.chkTech();
      }
    }
  }

  handleEventResizeStart(arg) {
  }

  handleEventResize(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.events[i]['start'] = arg.event.start;
        this.events[i]['end'] = arg.event.end;

        this.calendarOptions.events = this.chkTech();
      }
    }
  }

  addEvent() {

    this.submittedgen = true;

    if (!this.item['id'] || !this.item['start'] || (!this.item['end'] && !this.item['nolimit']))
      return;

    for (let i = 0; i < this.listdevs.length; i++) {
      if (this.listdevs[i]['id'] == this.item['id']) {
        this.item['name'] = this.listdevs[i]['name'];

        this.item['jobid'] = this.listdevs[i]['job'];
        this.item['color'] = this.getColor(this.listdevs[i]['job']);
      }
    }

    if (this.item['state'] == 'add') {
      this.events.push({ id: this.item['id'], title: this.item['name'], start: this.item['start'], end: this.item['nolimit'] ? '' : this.item['end'], nolimit: this.item['nolimit'] });
    }
    else {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i]['id'] == this.item['id']) {
          this.events[i] = this.item;
        }
      }

    }

    this.savefree();
    this.calendarOptions.events = this.chkTech();

    $("#myModal").modal("hide");
  }

  deleteEvent() {

    if (!this.item['id'] || this.item['state'] == 'add')
      return;

    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == this.item['id']) {
        this.events.splice(i, 1);
      }
    }

    this.savefree();
    this.calendarOptions.events = this.chkTech();

    $("#myModal").modal("hide");
  }

  changeExpNolimit(event) {
    delete this.item['end'];
  }

  addCourse() {
    this.course = {};
    this.submittedcourse = false;

    $("#addcourse").modal({
      backdrop: 'static',
      keyboard: false
    });
  }
  sendQuestion() {
    this.question = {};
    this.submittedquestion = false;

    $("#sendquestion").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  saveCourse() {
    this.submittedcourse = true;

    if (!this.course['name'] || !this.course['description'])
    return
    
    this.course['draft'] = true;
    this.course['userid'] = this.user['id'];
    this.course['date'] = new Date();

    this.dataService.create("courses", this.course).subscribe((data) => {
      $("#addcourse").modal("hide");
    });
  }

  
  saveQuestion() {
    this.submittedquestion = true;

    if (!this.question['name'] || !this.question['description'])
    return
    
    this.question['draft'] = true;
    this.question['userid'] = this.user['id'];
    this.question['date'] = new Date();

    this.dataService.create("questions", this.question).subscribe((data) => {
      $("#sendquestion").modal("hide");
    });
  }

  asks() {
    this.course = {};
    $("#asks").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  share() {
    this.course = {};
    $("#share").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showAdd() {
    this.dev = {};
    $("#cvgeneral").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showEdit(data) {
    let self = this;

    // if (!this.selectedRow) {
    //   this.message = "Select resource!";

    //   setTimeout(() => {
    //     delete self.message;
    //   }, 1000);

    //   return;
    // }

    // this.dev = this.selectedRow[0].data;

    this.dev = data;
    $("#cvgeneral").modal({
      backdrop: 'static',
      keyboard: false
    });
  }
  editCV(data) {
    this.selectedDev = data.id;
    this.tabadmin = 2;
  }

  showDelete(data) {
    let self = this;

    // if (!this.selectedRow) {
    //   this.message = "Select resource!";

    //   setTimeout(() => {
    //     delete self.message;
    //   }, 1000);

    //   return;
    // }

    if (!confirm('Delete?'))
      return;

    this.selectedRow = [data];
    for (let i = 0; i < this.selectedRow.length; i++) {
      this.deleteresource(this.selectedRow[i]['id']);
    }
  }

  addresouce() {
    this.submittedgen = true;

    if (!this.dev['name'] || !this.dev['job'] || !this.dev['level'])
      return;

    if (!this.dev['id']) {
      this.dev['id'] = UUID.UUID();
      this.listdevs.unshift(this.dev);
    }
    else {
      for (let i = 0; i < this.listdevs.length; i++) {
        if (this.listdevs[i]['id'] == this.dev['id']) {
          this.listdevs[i] = this.dev;
        }
      }
    }
    this.savedevs();

    if(document.getElementById('cvgeneralclose'))
    document.getElementById('cvgeneralclose').click();
    this.dev = {}
  }

  deleteresource(id) {

    // if (!confirm("Delete?"))
    //   return;

    for (let i = 0; i < this.listdevs.length; i++) {
      if (this.listdevs[i]['id'] == id) {

        if (!this.user['devsdeleted'])
          this.user['devsdeleted'] = [];

        this.user['devsdeleted'].push(this.listdevs[i]);

        this.listdevs.splice(i, 1);
      }
    }

    this.savedevs();
  }

  savefree() {
    let self = this;

    if (this.isAdminUsers)
      return;

    this.user['free'] = this.events;
    this.dataService.update("users", this.user).subscribe((data) => {
      self.savenotif('save event');
    });
  }

  savedevs() {
    let self = this;

    if (this.isAdminUsers)
      return;

    this.user['devs'] = this.listdevs;
    this.dataService.update("users", this.user).subscribe((data) => {
      self.savenotif('save dev');
    });
  }

  logout() {

    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  savenotif(content) {
    this.dataService.create("notifications", { id: UUID.UUID(), email: this.user['email'], content: content, date: new Date() }).subscribe((data) => {

    });
  }

  savenotifmentor(email) {
    this.dataService.create("notifications", { id: UUID.UUID(), email: email, content: 'mentor', date: new Date() }).subscribe((data) => {

    });
  }


  setStartExam() {

    if (!this.selectedDev || !this.selectedExam)
      return;

    this.startExam = true
  }

  setStopExam() {
    this.startExam = false;
  }

  generateLink() {
    let self = this;

    if (!this.selectedDev || !this.selectedExam)
      return;

    this.genLinkID = UUID.UUID();
    let item_ = { id: this.genLinkID, devid: this.selectedDev, examid: this.selectedExam, userid: this.user['id'], createdAt: new Date() };

    this.dataService.create("linksexam", item_).subscribe((data) => {
      self.savenotif('generate link exam');
    });
  }

  generateLinkCV() {

    if (!this.selectedDev)
      return;

    delete this.genLinkCVID;

    this.submittedcvlink = false;
    this.linkcv = {}

    $("#cvlink").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  saveGenerateLinkCv() {
    this.submittedcvlink = true;

    if (!this.linkcv['password'])
      return;

    let self = this;

    this.genLinkCVID = UUID.UUID();
    let item_ = { id: this.genLinkCVID, devid: this.selectedDev, password: btoa(this.linkcv['password']), userid: this.user['id'], createdAt: new Date() };

    this.dataService.create("linkscv", item_).subscribe((data) => {
      self.savenotif('generate link cv exam');
    });

  }

  copylink() {
    let self = this;
    var copyText = <HTMLInputElement>document.getElementById("copylink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.msgcopy = "Copied!";
    setTimeout(() => {
      self.msgcopy = '';
    }, 1000);
  }

  copylinkcv() {
    let self = this;
    var copyText = <HTMLInputElement>document.getElementById("copylinkcv");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");

    this.msgcopy = "Copied!";
    setTimeout(() => {
      self.msgcopy = '';
    }, 1000);
  }

  // listdevs{
  //   let result = [];

  //   if (!this.listdevs || !this.listdevs.length || this.listdevs.length == 0)
  //   this.listdevs = [];

  //   for(let i=0; i < this.listdevs.length; i++){
  //     if (!this.listdevs[i]['deleted'])
  //     result.push(this.listdevs[i]);
  //   }

  //   return result;
  // }


  coursedetail(id){
    this.startCourse = id;
  }

  showList(){
    $("#list").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showTimeline(){
    $("#timeline").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  isApplied(mentor){
    let self = this;
    let result = false;

    if (!mentor['students']) 
    mentor['students'] = []; 

    let temp = mentor['students'].filter(function (entry) {
      return entry['userid'] && self.user['id'];
    });

    if (temp && temp.length >0)
    result = true;

    return result;

  }

  apply(mentor){
    let self = this;

    if (!mentor['students']) 
    mentor['students'] = []; 

    let temp = mentor['students'].filter(function (entry) {
      return entry['userid'] && self.user['id'];
    });

    if (!temp || temp.length == 0){
      mentor['students'].push({id: this.user['id'], userid: this.user['id']});

      this.dataService.update("mentors", mentor).subscribe((data) => {
        this.savenotifmentor(mentor['email']);
      });
    }
  }

  unapply(mentor){
    //return;

    let self = this;

    if (!mentor['students']) 
    mentor['students'] = []; 

    let temp = mentor['students'].filter(function (entry) {
      return entry['userid'] && self.user['id'];
    });

    if (temp && temp.length > 0){
      mentor['students'].splice(this.findSelectedItem( mentor['students'], mentor), 1);

      this.dataService.update("mentors", mentor).subscribe((data) => {
      });
    }
  }

  getMentorsValid(){
    let result = [];

    result = this.mentors.filter(function (entry) {
      return entry['online'];
    })

    return result;
  }

  findSelectedItem(source, _item, id='id'):number {
    for (var key in source) {
        var item = source[key];
        if (item) {
            if (item[id] == _item[id])
                return parseInt(key);
        }
    }

    return -1;
}



}
