import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import * as listtech from 'src/_data/tech.json';
import { UUID } from 'angular2-uuid';
import { Utils } from 'src/app/shared/utils';

import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiRowSelectionMode, GuiSelectedRow, GuiColumnAlign, GuiPaging, GuiPagingDisplay, GuiRowColoring, GuiRowSelection, GuiRowSelectionType } from '@generic-ui/ngx-grid';

declare let $: any;
import { CalendarOptions } from '@fullcalendar/angular';
//import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

import { FileUpload } from 'src/app/models/fileupload';
import { UploadFileService } from 'src/app/services/upload-file.service';

import { DataService } from '../services/data.service';

@Component({
  selector: 'cvsuser',
  templateUrl: './cvsuser.component.html',
  styleUrls: ['./cvsuser.component.css']
})
export class CvsuserComponent implements OnInit {

  isRootAdmin;isAdmin;

  listusers;
  loaded; user; username;
  listfiles: any[] = [];   listfiles2: any[] = [];
  listprojects: any[] = [];
  tabadmin = 1;

  selectedFile;
  searchTerm = { name: '' };

  hrefCrypto;hrefSkills;

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private uploadService: UploadFileService) {

  }

  ngOnInit() {

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + '' + this.user['lastname'];
      else if (this.user && this.user['name'] && this.user['name'] != '')
        this.username = this.user['name'];
      else
        this.username = this.user['email'];

        this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
        this.isRootAdmin = (this.user['email'] == 'admin@admin.ro') && this.user['type'] && this.user['type'] == 'vvv';
        if (this.isRootAdmin)
        this.isAdmin = true; 
    }

    if (!this.user || !this.user['id']) {
      this.router.navigate(['/auth/login']);
      return;
    }

    
    this.dataService.getList("users").subscribe((dataUsers) => {

      this.listusers = dataUsers;

      this.dataService.getList("projects").subscribe((dataProject) => {

        this.dataService.getList("projects_cv").subscribe((dataFile) => {
          let self = this;
  
          if (dataFile) {
  
              this.listfiles = dataFile.filter(function (entry) {
                return entry['userid'] == self.user['id'];
              })


            //set user
            this.listfiles.forEach(elementF => {
              this.listusers.forEach(elementU => {
                if (elementF['userid'] && elementF['userid'] == elementU['id'])
                elementF['username'] = elementU['name'];
              });
            });
  
            if (dataProject) {
              dataFile.forEach(elementFile => {
  
                elementFile['projects'] = [];
                dataProject.forEach(elementProject => {
  
                  if (elementProject['cvc']){
                      if (elementProject['cvc'][this.user['id']]) {
                        elementProject['cvc'][this.user['id']].forEach(element => {
                          if (!element['deleted'] && element['id'] == elementFile['id']) {
                            elementFile['projects'].push({date:element['createdAt']?element['createdAt']:elementFile['createdAt'], project: elementProject});
                          }
                        });
                      }
                  }
                });
              });
            }
  
          }
  
          this.filterFiles();
          this.loaded = true;
        });
  
      })

    });

  }

  deleteCv(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    this.dataService.delete("projects_cv", data['id']).subscribe((data) => {
    })
  }

  filterFiles() {
    this.listfiles2 = [];

    if (!this.searchTerm || !this.searchTerm['name'])
      return this.listfiles2 = this.listfiles;

      for (let i = 0; i < this.listfiles.length; i++) {
        if (Utils.contain(this.listfiles[i]['name'], this.searchTerm['name']) ||
          Utils.contain(this.listfiles[i]['username'], this.searchTerm['name']))
          this.listfiles2.push(this.listfiles[i]);
      }

      return this.listfiles2;
  }

  showProjects(data) {
    this.selectedFile = data;
    this.listprojects = data['projects']?data['projects']:[];
    $("#projects").modal();
  }

  showobsadminxyz123(data){
    this.selectedFile = data;
    $("#obsadminxyz123").modal();
  }

  saveobsadminxyz123(){
    this.dataService.update("projects_cv", this.selectedFile).subscribe((dataFile) => {
      $("#obsadminxyz123").modal("hide");
      delete this.selectedFile;
    });
  }

  showobs(data){
    this.selectedFile = data;
    $("#obs").modal();
  }

  saveobs(){
    this.dataService.update("projects_cv", this.selectedFile).subscribe((dataFile) => {
      $("#obs").modal("hide");
      delete this.selectedFile;
    });
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }
}
