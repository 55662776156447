import { NgModule } from "@angular/core";
import { SortPipe } from "./sort";
import { SafeHtmlPipe } from "./safehtml";
import { FilterPipe } from "./filter";
import { FilterBPipe } from "./filterb";

@NgModule({
    declarations: [SortPipe, SafeHtmlPipe, FilterPipe, FilterBPipe],
    exports: [SortPipe, SafeHtmlPipe, FilterPipe, FilterBPipe]
})
export class PipesModule {}