import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from '../services/data.service';

@Component({
    selector: 'coursedetail',
    styleUrls: ['./coursedetail.component.css'],
    templateUrl: './coursedetail.component.html'
})
export class CoursedetailComponent implements OnInit {

    @Input() courseid: any;

    user: any = {};
    userinfo: any = {};
    item: any; usercourse: any;
    arrexams;

    constructor(private dataService: DataService, private route: ActivatedRoute, private elementRef: ElementRef) {

    }

    ngOnInit() {

        let tmp = localStorage.getItem('currentUser');
        if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
          this.user = JSON.parse(tmp);
        }

        this.dataService.getList("exams").subscribe(data => {
            this.arrexams = data;
        });

        this.dataService.getList("courses").subscribe(data => {

            for (var key in data) {
                var item = data[key];
                if (item) {
                    if (item['id'] === this.courseid) {

                        this.item = item;

                        break;
                    }
                }
            }

            this.dataService.getByID("users", this.user.id).subscribe(data => {

                if (data) {


                    this.usercourse = {
                        "id": this.item.id,
                        "name": this.item.name,
                        "category": this.item.category,
                        "lessons": []
                    }

                    this.userinfo = data;

                    if (!this.userinfo.courses)
                        this.userinfo.courses = [];

                    let found = false;
                    for (var key in this.userinfo.courses) {
                        let item = {};
                        item = this.userinfo.courses[key];
                        if (item) {
                            if (item['id'] === this.item.id) {
                                found = true;
                                this.usercourse = item;
                                break;
                            }
                        }
                    }

                    if (!found) {
                        this.userinfo.courses.unshift(this.usercourse)
                    }

                    this.usercourse.countlessons = 0;

                    for (var key in this.item.listcap) {
                        let item = {};
                        item = this.item.listcap[key];
                        if (item && item['lessons']) {
                            this.usercourse.countlessons = this.usercourse.countlessons + item['lessons'].length;
                            for (var key2 in item['lessons']) {
                                var item2 = {};
                                item2 = item['lessons'][key2];
                                if (item2) {
                                    for (var key3 in this.usercourse.lessons) {
                                        var item3 = this.usercourse.lessons[key3];
                                        if (item3) {
                                            if (item2['id'] === item3.id) {
                                                item2['check'] = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    this.savecourse();

                }

            });

        });
    }

    savecourse() {

        this.usercourse.lessons = [];

        for (var key in this.item.listcap) {
            var item = this.item.listcap[key];
            if (item && item.lessons) {
                for (var key2 in item.lessons) {
                    var item2 = item.lessons[key2];
                    if (item2) {
                        if (item2.check && item2.check == true) {
                            this.usercourse.lessons.unshift({ "id": item2.id })
                        }
                    }
                }
            }
        }

        this.dataService.update("users", this.userinfo);
    }

    getExamId(category) {

        for (var keyexam1 in this.arrexams) {

            var itemexam1 = this.arrexams[keyexam1];
            if (itemexam1) {
                if (itemexam1['category'].toLowerCase() === category.toLowerCase())
                    return itemexam1['id'];
            }
        }

        return '';
    }
}
