import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {UUID} from 'angular2-uuid';
// import { AngularFirestore } from '@angular/fire/firestore';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from 'angularfire2/firestore';
import {firebase} from '@firebase/app';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';

import {of as observableOf, from as observableFrom, Observable, of} from 'rxjs';
import {map, switchMap, tap, catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  public  data: any[]; 
  baseUri;
  dataRef: AngularFirestoreCollection<any> = null;

  constructor(public firestore: AngularFirestore, private router : Router, private http: HttpClient) { 
    this.baseUri = 'http://localhost:3000';
  }

  ngOnInit() {
  }

  
  getList(table): Observable<any[]> {
    this.dataRef = this.firestore.collection(table);
    return this.dataRef.valueChanges();
  }
  getByID(table,id){
    this.dataRef = this.firestore.collection(table);
    let item =  this.dataRef.doc(id)  as AngularFirestoreDocument<any>;
    return observableFrom(item.valueChanges()).pipe(
      tap((rs) => console.log('get item: ')));
  }

  create(table,item): any {

    let forS = {};
    this.dataRef = this.firestore.collection(table);

    if (!item.id) {
      item.id = UUID.UUID();

      if (item['createAt'])
      forS['createAt'] = item['createAt'];
      else
      forS['createAt'] = new Date();
      
      forS['updatedAt'] = new Date();
    } else {
      forS['updatedAt'] = new Date();
    }

    forS = {...forS, ...JSON.parse(JSON.stringify(item))};
    console.log('Saving ');
    
    return observableFrom(this.dataRef.doc(item.id).set(forS)).pipe(
      tap((rs) => console.log('save item:')));
  }
  update(table,item){
    this.dataRef = this.firestore.collection(table);
    return observableFrom(this.dataRef.doc(item.id).update(item)).pipe(
      tap((rs) => console.log('Update item: ')));
  }
  delete(table,id: string) {
    this.dataRef = this.firestore.collection(table);
    let item = this.dataRef.doc(id) as AngularFirestoreDocument<any>;
    return observableFrom(item.delete());
  }

  sendMail(data: object) {
    return this.http.post<any[]>(`${this.baseUri}/mail`, data).pipe(
      tap((data: any) => console.log('added item')),
      catchError(this.handleError<any>('create item'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
