import { Component, OnInit, Input } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import { UUID } from 'angular2-uuid';

import { Utils } from 'src/app/shared/utils';

import { FileUpload } from 'src/app/models/fileupload';
import { UploadFileService } from 'src/app/services/upload-file.service';

import { DataService } from '../services/data.service';

@Component({
  selector: 'cv',
  templateUrl: './cv.component.html',
  styleUrls: ['./cv.component.css']
})
export class CvComponent implements OnInit {

  isAdminUsers;

  @Input() readonly;

  cvForm: FormGroup;
  submitted: boolean;
  submittedgen: boolean;
  submittedskill: boolean;
  submittedexp: boolean;
  submittededu: boolean;

  //upload file
  selectedFile: any;
  currentItem: any;
  currentFileUpload: any;
  startupload: boolean = false;
  message: string = '';
  percentage: number = 0;

  setDevID;
  listtech = [];

  config = {
    displayKey: "name"
  }

  listfiles_user;selectedFile_user;

  linkcv = {}; linkcvid;
  submittedcvlink; msgpassword;
  linkcv_validpassword;

  @Input()
  set setDev(id: any) {

    if (!id) {
      //this.dev={skills:[], experience:[], education:[]};
      return;
    }

    this.setDevID = id;
    this.ngOnInit();
  }

  @Input() setListDevs;


  allusers;
  user; username;
  itemtemp;
  listdel = [];
  loaded; countries;
  bSave = false;
  tabadmin = 1;
  error; info;

  listalldevs;
  dev;//{skills:[], experience:[], education:[]}; 
  dev_temp; listdevs = [];
  skillitem;
  skill = {}; listskills = [];
  exp = {}; listexp = [];
  edu = {}; listedu = [];

  listSkillCategories = [];

  dropdownSettings = {};

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private route: ActivatedRoute,
    private uploadService: UploadFileService) {

    this.listtech = [
      {
        id: 1,
        name: 'Angular'
      },
      {
        id: 2,
        name: 'ReactJs'
      },
      {
        id: 3,
        name: 'VueJs'
      },
      {
        id: 4,
        name: 'NodeJs'
      },
      {
        id: 5,
        name: 'Java'
      }, {
        id: 6,
        name: 'C#'
      },
      {
        id: 7,
        name: 'Python'
      },
      {
        id: 8,
        name: 'Php'
      }
    ]

    this.listSkillCategories = [
      {
        id: 1,
        name: 'Programming Languages/ Technologies'
      },
      {
        id: 2,
        name: 'DBMS'
      },
      {
        id: 3,
        name: 'Development Tools'
      },
      {
        id: 4,
        name: 'Testing Tools'
      },
      {
        id: 5,
        name: 'Other Frameworks/Libraries'
      },
      {
        id: 6,
        name: 'Other'
      }
    ]


  }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    let self = this;
    this.countries = countries['default'];

    this.submitted = false;
    this.cvForm = this.fb.group({
      name: ['', [Validators.required]]
    });

      let tmp = localStorage.getItem('currentUser');
      if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
        this.user = JSON.parse(tmp);
        if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
          this.user['lastname'] && this.user['lastname'] != '')
          this.username = this.user['firstname'] + '' + this.user['lastname'];
        else
          this.username = this.user['email'];
  
        this.isAdminUsers = this.user['type'] && this.user['type'] == 'vvv';
      }

    this.route.params.subscribe(params => {
      let self = this;

      if (window.location.href.toLowerCase().indexOf('/cv/') >=0 && params['id']) {

        this.dataService.getByID("linkscv", params['id']).subscribe((data) => {
          if (data) {
            this.linkcvid = data['id'];
            this.setDevID = data['devid'];
            this.linkcv = data;
            this.getOnlyUser(data['userid']);
          }
          else {
            this.router.navigate(['/auth/login']);
            return;
          }
        });

      }
      else {
        if (!this.user || !this.user['id']) {
          this.router.navigate(['/auth/login']);
          return;
        }

        if (this.isAdminUsers) {
          this.getAllUser();
        }
        else {
          this.getOnlyUser();
        }
      }
    });

    this.dataService.getList("projects_cv").subscribe((data) => {
      let self = this;

      if (data) {
        this.listfiles_user = data.filter(function (entry) {
          return entry['userid'] == self.user['id'];
        })
      }
    });

  }

  getOnlyUser(id_ = '') {
    let self = this;

    if (id_ == '')
      id_ = this.user['id'];

    this.dataService.getByID("users", id_).subscribe((data) => {
      this.user = data;

      if (!this.user || !this.user['id']) {
        this.router.navigate(['/auth/login']);
        return;
      }

      if (!this.user['devs'])
        this.user['devs'] = [];

        if (this.setListDevs)
        this.listdevs = this.setListDevs;
        else
        this.listdevs = this.user['devs'];

      if (this.user['devs'].length == 0)
        this.user['devs'].push({ id: UUID.UUID(), skills: [], experience: [], education: [] });


      //set devs
      let found;
      for (let i = 0; i < this.listdevs.length; i++) {
        if (this.listdevs[i]['id'] == this.setDevID) {

          found = true;
          this.dev = this.listdevs[i];

          if (!this.dev['skills'])
            this.dev['skills'] = [];

          if (!this.dev['experience'])
            this.dev['experience'] = [];

          if (!this.dev['education'])
            this.dev['education'] = [];
        }
      }
      if (!found)
        this.dev = { skills: [], experience: [], education: [] };

      this.listskills = this.dev['skills'];
      self.loaded = true;


    });
  }

  getAllUser() {
    let self = this;

    this.dataService.getList("users").subscribe((data) => {
      this.allusers = data;

      if (!this.user || !this.user['id']) {
        this.router.navigate(['/auth/login']);
        return;
      }

      this.listdevs = []; this.listalldevs = [];
      for (let i = 0; i < this.allusers.length; i++) {

        if (!this.allusers[i]['devs'])
          this.allusers[i]['devs'] = [];

        if (!(this.allusers[i]['devs'] instanceof Array))
          this.allusers[i]['devs'] = [];

        for (let i2 = 0; i2 < this.allusers[i]['devs'].length; i2++) {
          this.allusers[i]['devs'][i2]['company'] = this.allusers[i]['name'] + '/' + this.allusers[i]['email'];
        }

        this.listalldevs = [...this.listalldevs, ...this.allusers[i]['devs']];
      }

      this.listdevs = this.listalldevs;

      if (this.listdevs.length == 0)
        this.listdevs.push({ id: UUID.UUID(), skills: [], experience: [], education: [] });


      //set devs
      let found;
      for (let i = 0; i < this.listdevs.length; i++) {
        if (this.listdevs[i]['id'] == this.setDevID) {

          found = true;
          this.dev = this.listdevs[i];

          if (!this.dev['skills'])
            this.dev['skills'] = [];

          if (!this.dev['experience'])
            this.dev['experience'] = [];

          if (!this.dev['education'])
            this.dev['education'] = [];
        }
      }
      if (!found)
        this.dev = { skills: [], experience: [], education: [] };

      this.listskills = this.dev['skills'];
      self.loaded = true;


    });
  }

  editLinkCv() {
    this.submittedcvlink = true;
    this.linkcv_validpassword = false;

    if (!this.linkcv['pass']) {
      this.msgpassword = "Password require!";
      return;
    }

    if (this.linkcv['password'] && this.linkcv['password'] != '' && this.linkcv['pass'] != atob(this.linkcv['password'])) {
      this.msgpassword = "Password invalid!";
      return;
    }

    this.msgpassword = ''
    this.linkcv_validpassword = true;

  }

  get form() {
    return this.cvForm.controls;
  }

  showgeneral() {
    this.submittedgen = false;
    this.dev_temp = JSON.parse(JSON.stringify(this.dev));
  }
  savegeneral() {

    this.submittedgen = true;

    if (!this.dev_temp['name'] || !this.dev_temp['job'] || !this.dev_temp['level'])
      return;

    this.dev['name'] = this.dev_temp['name'];
    this.dev['job'] = this.dev_temp['job'];
    this.dev['level'] = this.dev_temp['level'];

    this.savebase();

    document.getElementById('cvgeneralclose').click();
    delete this.dev_temp;
  }

  showskills() {
    this.submittedskill = false;
    this.dev_temp = JSON.parse(JSON.stringify(this.dev));
  }
  saveskills() {

    this.submittedgen = true;

    this.dev['skills'] = this.dev_temp['skills'];
    this.savebase();

    this.skill = {};
    //document.getElementById('cvskillsclose').click();
    //delete this.dev_temp;
  }

  showexps() {
    this.submittedexp = false;
    this.dev_temp = JSON.parse(JSON.stringify(this.dev));
  }
  saveexps() {

    this.submittedgen = true;

    this.dev['experience'] = this.dev_temp['experience'];
    this.savebase();

    this.exp = {};
    // document.getElementById('cvexpclose').click();
    // delete this.dev_temp;
  }

  showedus() {
    this.submittededu = false;
    this.dev_temp = JSON.parse(JSON.stringify(this.dev));
  }
  saveedus() {

    this.submittededu = true;

    this.dev['education'] = this.dev_temp['education'];
    this.savebase();

    this.edu = {};
    // document.getElementById('cveduclose').click();
    // delete this.dev_temp;
  }


  savenotif(content) {
    if (this.user['email'] === 'maleeshasafdari8@gmail.com')
      return;

    this.dataService.create("notifications", { id: UUID.UUID(), email: this.user['email'], content: content, date: new Date() }).subscribe((data) => {

    });
  }

  savebase() {

    let self = this;

    this.dataService.update("users", this.user).subscribe((data) => {
      this.bSave = false;
      this.info = "Saved!";
      //self.savenotif('send dev');
    });

  }


  //skills

  addskill() {
    this.submittedskill = true;

    if (!this.skill['category'])
      return;

    this.addskillitem();

    if (!this.dev_temp['skills'] || !this.dev_temp['skills'].length || this.dev_temp['skills'].length == 0)
      this.dev_temp['skills'] = [];

    if (!this.skill['id']) {
      this.skill['id'] = Utils.guid();
      this.dev_temp['skills'].push(this.skill);
    }
    else {
      for (let i = 0; i < this.dev_temp['skills'].length; i++) {
        if (this.dev_temp['skills'][i]['id'] == this.skill['id'])
          this.dev_temp['skills'][i] = JSON.parse(JSON.stringify(this.skill));
      }
    }

    this.saveskills();

    this.skill = {};
    this.submittedskill = false;
  }

  addskillitem() {
    if (!this.skillitem)
      return;

    if (!this.skill['list'] || !this.skill['list'].length || this.skill['list'].length == 0)
      this.skill['list'] = [];

    if (this.skillitem.indexOf(',')) {
      let arr = this.skillitem.split(',');
      for (let i = 0; i < arr.length; i++) {
        this.skill['list'].push({ id: UUID.UUID(), name: arr[i] });
      }
    }
    else {
      this.skill['list'].push({ id: UUID.UUID(), name: this.skillitem });
    }

    delete this.skillitem;
  }

  editskill(item) {
    this.skill = JSON.parse(JSON.stringify(item));
  }
  deleteskill(id) {
    if (!confirm("Delete?"))
      return;

    if (!this.dev_temp['skills'] || !this.dev_temp['skills'].length || this.dev_temp['skills'].length == 0)
      this.dev_temp['skills'] = [];

    for (let i = 0; i < this.dev_temp['skills'].length; i++) {
      if (this.dev_temp['skills'][i]['id'] == id)
        this.dev_temp['skills'].splice(i, 1);
    }

    this.saveskills();
  }
  deleteskillitem(id) {

    if (!this.skill['list'] || !this.skill['list'].length || this.skill['list'].length == 0)
      this.skill['list'] = [];

    for (let i = 0; i < this.skill['list'].length; i++) {
      if (this.skill['list'][i]['id'] == id)
        this.skill['list'].splice(i, 1);
    }
  }

  getskills() {

    // let result = [{
    //   id: -1,
    //   category: -1,
    //   list: [{
    //     id: 1,
    //     name: 'skill1'
    //   },
    //   {
    //     id: 2,
    //     name: 'skill2'
    //   },
    //   {
    //     id: 3,
    //     name: 'skill3'
    //   }]
    // }];
    let result = [];

    if (!this.dev['skills'] || !this.dev['skills'].length || this.dev['skills'].length == 0)
      this.dev['skills'] = [];

    if (this.dev['skills'].length > 0)
      return this.dev['skills']
    else
      return result;
  }
  getskillsmodal() {

    if (!this.dev_temp['skills'] || !this.dev_temp['skills'].length || this.dev_temp['skills'].length == 0)
      this.dev_temp['skills'] = [];

    return this.dev_temp['skills']
  }
  getskillitems(skill) {

    if (!skill['list'] || !skill['list'].length || skill['list'].length == 0)
      skill['list'] = [];

    return skill['list'];
  }
  getskillcategory(categoryid) {

    let result = '';

    if (!categoryid)
      return result;

    if (categoryid == -1)
      return 'category';

    for (let i = 0; i < this.listSkillCategories.length; i++) {
      if (this.listSkillCategories[i]['id'] == categoryid)
        result = this.listSkillCategories[i]['name'];
    }

    return result;
  }

  getskills_old() {
    let result = [];

    if (!this.listskills || !this.listskills.length || this.listskills.length == 0)
      this.listskills = [];

    return result;
  }


  //experience
  saveexp() {

    let self = this;
    this.submittedexp = true;
    delete this.error;
    delete this.info;

    this.user['skills'] = this.listexp;

    if (!this.user['country']) {
      this.error = "Country is required!"
      return;
    }

    this.getexp();

    if (this.getexp().length == 0) {
      this.error = "Skills are mandatory!"
      return;
    }

    this.bSave = true;

    this.dataService.update("users", this.user).subscribe((data) => {
      this.submittedexp = false;
      this.bSave = false;
      this.info = "Saved!";
      self.savenotif('send skills');
    });

  }
  addexp() {
    this.submittedexp = true;

    if (!this.exp['name'] || !this.exp['details'] || !this.exp['start'] || (!this.exp['end'] && !this.exp['present']))
      return;

    if (!this.listexp || !this.listexp.length || this.listexp.length == 0)
      this.listexp = [];

    if (!this.exp['id']) {
      this.exp['id'] = Utils.guid();
      this.dev_temp['experience'].push(this.exp);
    }
    else {
      for (let i = 0; i < this.dev_temp['experience'].length; i++) {
        if (this.dev_temp['experience'][i]['id'] == this.exp['id'])
          this.dev_temp['experience'][i] = JSON.parse(JSON.stringify(this.exp));
      }
    }

    this.saveexps();

    this.exp = {};
    this.submittedexp = false;
  }

  changeExpPresent(event) {
    delete this.exp['end'];
  }
  changeEduPresent(event) {
    delete this.edu['end'];
  }

  getexp() {

    // let result = [{
    //   name: 'name',
    //   details: 'details'
    // }];

    let result = [];

    if (!this.dev['experience'] || !this.dev['experience'].length || this.dev['experience'].length == 0)
      this.dev['experience'] = [];

    if (this.dev['experience'].length > 0)
      return this.dev['experience'];
    else
      return result;
  }

  getexpmodal() {

    if (!this.dev['experience'] || !this.dev['experience'].length || this.dev['experience'].length == 0)
      this.dev['experience'] = [];

    return this.dev['experience'];
  }

  getexp_old() {
    let result = [];

    if (!this.listexp || !this.listexp.length || this.listexp.length == 0)
      this.listexp = [];

    for (let i = 0; i < this.listexp.length; i++) {
      if (!this.listexp[i]['delete'])
        result.push(this.listexp[i]);
    }

    return result;
  }

  editexp(item) {
    this.exp = JSON.parse(JSON.stringify(item));
  }
  deleteexp(id) {
    if (!confirm("Delete?"))
      return;

    if (!this.dev_temp['experience'] || !this.dev_temp['experience'].length || this.dev_temp['experience'].length == 0)
      this.dev_temp['experience'] = [];

    for (let i = 0; i < this.dev_temp['experience'].length; i++) {
      if (this.dev_temp['experience'][i]['id'] == id)
        this.dev_temp['experience'].splice(i, 1);
    }

    this.saveexps();
  }


  //education
  saveedu() {

    let self = this;
    this.submittededu = true;
    delete this.error;
    delete this.info;

    this.user['skills'] = this.listedu;

    if (!this.user['country']) {
      this.error = "Country is required!"
      return;
    }

    this.getedu();

    if (this.getedu().length == 0) {
      this.error = "Skills are mandatory!"
      return;
    }

    this.bSave = true;

    this.dataService.update("users", this.user).subscribe((data) => {
      this.submittededu = false;
      this.bSave = false;
      this.info = "Saved!";
      self.savenotif('send skills');
    });

  }
  addedu() {
    this.submittededu = true;

    if (!this.edu['name'] || !this.edu['details'] || !this.edu['start'] || (!this.edu['end'] && !this.edu['present']))
      return;

    if (!this.listedu || !this.listedu.length || this.listedu.length == 0)
      this.listedu = [];

    if (!this.edu['id']) {
      this.edu['id'] = Utils.guid();
      this.dev_temp['education'].push(this.edu);
    }
    else {
      for (let i = 0; i < this.dev_temp['education'].length; i++) {
        if (this.dev_temp['education'][i]['id'] == this.edu['id'])
          this.dev_temp['education'][i] = JSON.parse(JSON.stringify(this.edu));
      }
    }

    this.saveedus();

    this.edu = {};
    this.submittededu = false;
  }

  getedu() {

    // let result = [{
    //   name: 'name',
    //   details: 'details'
    // }];

    let result = [];

    if (!this.dev['education'] || !this.dev['education'].length || this.dev['education'].length == 0)
      this.dev['education'] = [];

    if (this.dev['education'].length > 0)
      return this.dev['education'];
    else
      return result;
  }

  getedumodal() {

    if (!this.dev['education'] || !this.dev['education'].length || this.dev['education'].length == 0)
      this.dev['education'] = [];

    return this.dev['education'];
  }

  editedu(item) {
    this.edu = JSON.parse(JSON.stringify(item));
  }
  deleteedu(id) {
    if (!confirm("Delete?"))
      return;

    if (!this.dev_temp['education'] || !this.dev_temp['education'].length || this.dev_temp['education'].length == 0)
      this.dev_temp['education'] = [];

    for (let i = 0; i < this.dev_temp['education'].length; i++) {
      if (this.dev_temp['education'][i]['id'] == id)
        this.dev_temp['education'].splice(i, 1);
    }

    this.saveedus();
  }

  topFunction() {
    this.tabadmin = 4;

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }


  //upload file
  selectFileOption(item) {
    //this.selectedFile_user
  }
  selectFile(event: any) {
    this.selectedFile = event.target.files;
    //this.uploadFile();
  }
  uploadFile() {

    let self = this;

    if (!this.selectedFile && this.selectedFile_user) {
    
      this.message = "waiting ..."

      if (!this.dev['cvdeleted'])
      this.dev['cvdeleted'] = [];

    if (this.dev['cvid'] && this.dev['cvurl'])
      this.dev['cvdeleted'].push({ cvid: this.dev['cvid'], cvurl: this.dev['cvurl'] })

    this.dev['cvid'] = self.selectedFile_user['id'];
    this.dev['cvurl'] = self.selectedFile_user['file_url'];

    this.dataService.update("users", this.user).subscribe((data) => {
      self.savenotif('upload cv');
      self.cancelFile();
      delete self.message;
    });
    }

    if (!this.selectedFile)
    return;

    this.startupload = true;

    let file = <File>this.selectedFile.item(0);
    delete this.selectedFile;

    this.currentItem = { id: Utils.guid(), createdAt: new Date(), name: file.name, userid: this.user['id'] }

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];

    this.message = "waiting ..."
    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload, "cv", this.currentItem, 'cv', ext).subscribe(

      async percentage => {
        this.percentage = Math.round(percentage);

        if (this.percentage == 100) {
          this.currentFileUpload = undefined;

          let nr = 5000;
          while (!self.currentItem['file_url'] && nr >= 0) {
            await new Promise(resolve => setTimeout(() => resolve(), 100));
            nr = nr - 10;
          }

          if (!this.dev['cvdeleted'])
            this.dev['cvdeleted'] = [];

          if (this.dev['cvid'] && this.dev['cvurl'])
            this.dev['cvdeleted'].push({ cvid: this.dev['cvid'], cvurl: this.dev['cvurl'] })

          this.dev['cvid'] = self.currentItem['id'];
          this.dev['cvurl'] = self.currentItem['file_url'];

          this.dataService.update("users", this.user).subscribe((data) => {
            self.savenotif('upload cv');
            self.cancelFile();
            delete self.message;
          });

        }
      },
      error => {
        //console.log(error);
      }
    );
  }
  deleteFile() {

    if (!confirm('Delete?'))
      return;

    if (!this.dev['cvdeleted'])
      this.dev['cvdeleted'] = [];

    this.dev['cvdeleted'].push({ cvid: this.dev['cvid'], cvurl: this.dev['cvurl'] })

    delete this.dev['cvid'];
    delete this.dev['cvurl'];

    this.dataService.update("users", this.user).subscribe((data) => {
    });
  }
  cancelFile() {
    delete this.selectedFile;
    delete this.selectedFile_user;
  }
}
