import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import * as courses from 'src/_data/courses.json';
import { UUID } from 'angular2-uuid';
import { Utils } from 'src/app/shared/utils';

import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiRowSelectionMode, GuiSelectedRow, GuiColumnAlign, GuiPaging, GuiPagingDisplay, GuiRowColoring, GuiRowSelection, GuiRowSelectionType } from '@generic-ui/ngx-grid';

declare let $: any;
import { CalendarOptions } from '@fullcalendar/angular';
//import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

import { DataService } from '../services/data.service';

@Component({
  selector: 'courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {

  //options: FullCalendarOptions;
  //events: EventObject[];

  hrefCrypto;hrefSkills;

  allcommnetsdraft;
  allresourcedraft; allcommentsdraft;

  selectedCourse;
  loaded; submitted; submittedgen;
  user; username; selectedRow; message;

  listtech = [];

  comments; refcom;
  course = {}; selecteCourse; listcourses = [];
  tabadmin = 1;

  ref = {}; submittedref; msgref;

  commwrite = {}; submittedcom;

  isAdmin = false;
  searchTerm = { name: '' };
  searchTerm2 = { name: '' };

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private fb: FormBuilder,
    private dataService: DataService) {

  }

  ngOnInit() {

    this.listtech = courses['default'];

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + '' + this.user['lastname'];
      else
        this.username = this.user['email'];

      this.isAdmin = this.user['type'] && this.user['type'] == 'vvv';


      this.dataService.getByID("users", this.user['id']).subscribe((data) => {
        this.user = data;
      });

    }

    if (!this.user || !this.user['id']){
      this.router.navigate(['/auth/login']);
      return;
    }

    this.dataService.getList("courses").subscribe((data) => {
      this.loaded = true;
      this.listcourses = data;

      this.allresourcedraft = 0;this.allcommentsdraft = 0;

      for (let i = 0; i < this.listcourses.length; i++) {

        if (!this.listcourses[i]['reference'])
          this.listcourses[i]['reference'] = [];

        this.listcourses[i]['referencedraft'] = this.listcourses[i]['reference'].filter(function (entry) {
          return entry['draft'];
        });

        this.allresourcedraft = this.allresourcedraft + this.listcourses[i]['referencedraft'].length;

        this.listcourses[i]['referenceactive'] = this.listcourses[i]['reference'].filter(function (entry) {
          return entry['active'];
        });

        for (let i2 = 0; i2 < this.listcourses[i]['reference'].length; i2++) {

          if (!this.listcourses[i]['reference'][i2]['comments'])
          this.listcourses[i]['reference'][i2]['comments'] = [];

          let arr = this.listcourses[i]['reference'][i2]['comments'].filter(function (entry) {
            return entry['draft'];
          });
          this.allcommentsdraft = this.allcommentsdraft + arr.length;
        }

        for (let i2 = 0; i2 < this.listtech.length; i2++) {
          if (this.listcourses[i]['tech'] == this.listtech[i2]['id']) {
            this.listcourses[i]['name'] = this.listtech[i2]['name'];
            this.listcourses[i]['file'] = (this.listtech[i2]['name'].toLowerCase() == 'c#') ? 'csharp' : this.listtech[i2]['name'];
          }
        }
      }
    });

  }

  sorterFunc = function (item) {
    return parseInt(item.id);
  }

  getColor(job) {

    if (job == 'Angular')
      return '#2165BA'
    else if (job == 'ReactJs')
      return '#3BBEE8'
    else
      return 'gray'
  }


  showAdd() {
    let type = '';
    if (this.tabadmin == 2)
      type = 'Front-end';
    if (this.tabadmin == 3)
      type = 'Front-end';
    if (this.tabadmin == 4)
      type = 'Front-end';

    this.course = { type: type };
    $("#cvgeneral").modal({
      backdrop: 'static',
      keyboard: false
    });
  }

  showEdit(data) {
    let self = this;

    this.course = data;
    $("#cvgeneral").modal({
      backdrop: 'static',
      keyboard: false
    });
  }
  editCV(data) {
    this.selectedCourse = data.id;
    this.tabadmin = 2;
  }

  addcourse() {

    let self = this;
    this.submittedgen = true;

    if (!this.course['tech'] || !this.course['type'])
      return;

    for (let i2 = 0; i2 < this.listtech.length; i2++) {
      if (this.course['tech'] == this.listtech[i2]['id']) {
        this.course['name'] = this.listtech[i2]['name'];
        this.course['file'] = (this.listtech[i2]['name'].toLowerCase() == 'c#') ? 'csharp' : this.listtech[i2]['name'];
      }
    }

    this.listcourses.push(this.course);
    this.dataService.create("courses", this.course).subscribe((data) => {
      $("#cvgeneral").modal('hide');
      self.course = {}
    });

  }

  deletecourse(data) {

    let self = this;

    if (!confirm('Delete?'))
      return;

    this.dataService.delete("courses", data['id']).subscribe((data) => {
      $("#cvgeneral").modal('hide');
      self.course = {}
    });
  }

  login() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  showReference(data) {
    let self = this;

    this.course = data;
    $("#reference").modal({
      backdrop: 'static',
      keyboard: false
    });
  }


  //reference
  addref() {
    let self = this;
    this.submittedref = true;

    if (!this.ref['name'] || !this.ref['link'])
      return;

    if (!this.ref['id']) {
      this.ref['draft'] = true;
      this.ref['id'] = Utils.guid();
      this.course['reference'].push(this.ref);
    }
    else {
      for (let i = 0; i < this.course['reference'].length; i++) {
        if (this.course['reference'][i]['id'] == this.ref['id']) {
          this.course['reference'][i] = this.ref;
        }
      }

    }

    this.dataService.update("courses", this.course).subscribe((data) => {
      self.ref = {};
      self.msgref = "Sent for approval!"

      setTimeout(() => {
        self.msgref = '';
      }, 2000);
    })

    this.submittedref = false;
    this.ref = {};
  }

  editref(data) {
    let self = this;

    this.ref = JSON.parse(JSON.stringify(data));
  }
  deleteref(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    for (let i = 0; i < this.course['reference'].length; i++) {
      if (this.course['reference'][i]['id'] == data['id']) {
        this.course['reference'].splice(i, 1);
      }
    }

    this.dataService.update("courses", this.course).subscribe((data) => {
      self.ref = {}
    })
  }

  approveref(data) {
    let self = this;

    if (!confirm('Approve?'))
      return;

    delete data['draft'];
    data['active'] = true;

    for (let i = 0; i < this.course['reference'].length; i++) {
      if (this.course['reference'][i]['id'] == data['id']) {
        this.course['reference'][i] = data;
      }
    }

    this.dataService.update("courses", this.course).subscribe((data) => {

    })
  }

  undoapproveref(data) {
    let self = this;

    if (!confirm('Undo approve?'))
      return;

    delete data['active'];
    data['draft'] = true;

    for (let i = 0; i < this.course['reference'].length; i++) {
      if (this.course['reference'][i]['id'] == data['id']) {
        this.course['reference'][i] = data;
      }
    }

    this.dataService.update("courses", this.course).subscribe((data) => {

    })
  }


  //comments
  showComments(data) {
    this.comments = data;
    this.refcom = data;
  }
  hideComments() {
    delete this.comments;
  }
  addComment() {

    if (!this.user) {
      $("#reference").modal('hide');
      this.login();
      return;
    }

    let self = this;
    this.submittedcom = true;

    if (!this.commwrite['name'])
      return;

      this.commwrite['userid'] = this.user['id'];
      this.commwrite['draft'] = true;

    if (!this.refcom['comments'])
      this.refcom['comments'] = [];

    if (!this.commwrite['id']) {
      this.commwrite['id'] = Utils.guid();
      this.refcom['comments'].push(this.commwrite);
    }
    else {
      for (let i = 0; i < this.refcom['comments'].length; i++) {
        if (this.refcom['comments'][i]['id'] == this.commwrite['id']) {
          this.refcom['comments'][i] = this.commwrite;
        }
      }

    }

    this.dataService.update("courses", this.course).subscribe((data) => {
      self.commwrite = {};

      setTimeout(() => {
        self.msgref = '';
      }, 2000);
    })

    this.submittedcom = false;
    this.commwrite = {};
  }
  deleteComment(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    for (let i = 0; i < this.refcom['comments'].length; i++) {
      if (this.refcom['comments'][i]['id'] == data['id']) {
        this.refcom['comments'].splice(i, 1);
      }
    }

    this.dataService.update("courses", this.course).subscribe((data) => {
      self.ref = {}
    })
  }

}
