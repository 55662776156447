// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyBcUWlDPUlWPm7xvL-opqgO09I-iKMUMBg",
    // authDomain: "it2020-5f45f.firebaseapp.com",
    // databaseURL: "https://it2020-5f45f.firebaseio.com",
    // projectId: "it2020-5f45f",
    // storageBucket: "it2020-5f45f.appspot.com",
    // messagingSenderId: "888428733593",
    // appId: "1:888428733593:web:1523abd97b855a03ee8408"

    apiKey: "AIzaSyCYFvC1l9lb5o1Dxl0BMWiUS5q-VqnvezU",
    authDomain: "it-club-10.firebaseapp.com",
    databaseURL: "https://it-club-10.firebaseio.com",
    projectId: "it-club-10",
    storageBucket: "it-club-10.appspot.com",
    messagingSenderId: "869920350933",
    appId: "1:869920350933:web:7c036407f90ac503"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
