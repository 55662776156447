import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import * as listtech from 'src/_data/tech.json';
import { UUID } from 'angular2-uuid';
import { Utils } from 'src/app/shared/utils';

import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { GuiCellView, GuiColumn, GuiColumnMenu, GuiSearching, GuiDataType, GuiRowSelectionMode, GuiSelectedRow, GuiColumnAlign, GuiPaging, GuiPagingDisplay, GuiRowColoring, GuiRowSelection, GuiRowSelectionType } from '@generic-ui/ngx-grid';

declare let $: any;
declare let window: any;

import { CalendarOptions } from '@fullcalendar/angular';
//import { FullCalendarOptions, EventObject } from 'ngx-fullcalendar';

import { FileUpload } from 'src/app/models/fileupload';
import { UploadFileService } from 'src/app/services/upload-file.service';

import { DataService } from '../services/data.service';

@Component({
  selector: 'projects_admin',
  templateUrl: './projects_admin.component.html',
  styleUrls: ['./projects_admin.component.css']
})
export class ProjectsAdminComponent implements OnInit {

  //options: FullCalendarOptions;
  //events: EventObject[];
  hrefCrypto; hrefSkills;
  
  isRootAdmin; isAdmin;
  selectedDev;
  loaded; submitted; submittedgen;
  user; username; selectedRow; message;

  listusers = [];
  listusers_name = {};

  listprojects_unread_cv = {};
  listprojects_unread_com = {};

  listprojects_read_cv = {};
  listprojects_read_com = {};

  currentuser;

  columnsprojects = [];
  sorting;
  columnMenu: GuiColumnMenu;
  paging: GuiPaging;

  project = {}; selecteDev;
  listprojects = []; listprojects2 = [];
  tabadmin = 1;

  listcomments = [];
  commwrite = {}; submittedcom; msgref;
  groupedByUser; keys;

  all_unread_cv = 0;
  all_unread_com = 0;

  listfiles = [];

  eventsAll = []; events = []; arg; item;
  calendarOptions: CalendarOptions;

  listcvs=[];
  listtech = [];

  searchTerm = { name: '' };
  rowSelection: boolean | GuiRowSelection = {
    enabled: true,
    //type: GuiRowSelectionType.CHECKBOX
    //mode: GuiRowSelectionMode.MULTIPLE
  };

  listfiles_user; selectedFile_user;

  //upload file
  selectedFile: any;
  currentItem: any;
  currentFileUpload: any;
  startupload: boolean = false;
  percentage: number = 0;

  dropdownSettings = {};

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router,
    private fb: FormBuilder,
    private dataService: DataService,
    private uploadService: UploadFileService) {

  }

  ngOnInit() {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.listtech = listtech['default'];

    this.columnsprojects = [
      {
        field: 'name',
        header: 'Name'
      },
      {
        field: 'description',
        header: 'Job',
        width: 200
      }];

    this.sorting = {
      enabled: true,
      multiSorting: true
    };

    this.columnMenu = {
      enabled: false,
      columnsManager: false
    };

    this.paging = {
      enabled: true,
      page: 1,
      pageSize: 10,
      pageSizes: [5, 10, 25, 50],
      display: GuiPagingDisplay.ADVANCED
    };

    this.events = [
    ]

    //this.options = {
    //defaultDate: '2018-07-26',
    //editable: true
    //};

    this.calendarOptions = {
      headerToolbar: {
        left: 'dayGridYear,dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        center: 'title',
        right: 'today,prevYear,prev,next,nextYear'
      },
      views: {
        dayGridYear: {
          type: 'dayGrid',
          buttonText: 'year',
          duration: { year: 1 }
        }
      },
      initialView: 'dayGridMonth',  //listWeek
      editable: false,
      dateClick: this.handleDateClick.bind(this),
      eventClick: this.handleEventClick.bind(this),
      eventDragStart: this.handleEventDragStart.bind(this),
      eventDrop: this.handleEventDrop.bind(this),
      eventResizeStart: this.handleEventResizeStart.bind(this),
      eventResize: this.handleEventResize.bind(this),
      events: this.events
    }

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + '' + this.user['lastname'];
      else if (this.user && this.user['name'] && this.user['name'] != '')
        this.username = this.user['name'];
      else
        this.username = this.user['email'];

      this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
      this.isRootAdmin = (this.user['email'] == 'admin@admin.ro') && this.user['type'] && this.user['type'] == 'vvv';
      if (this.isRootAdmin)
      this.isAdmin = true;

      if (!this.isRootAdmin && !this.isAdmin) {
        this.router.navigate(['/auth/login']);
        return;
      }
    }

    if (!this.user || !this.user['id']) {
      this.router.navigate(['/auth/login']);
      return;
    }

    this.dataService.getByID("users", this.user['id']).subscribe((data) => {
      this.user = data;
      this.loaded = true;

      if (!this.user || !this.user['id']) {
        this.router.navigate(['/auth/login']);
        return;
      }

      if (!this.user['projects'])
        this.user['projects'] = [];

      if (!this.user['free'])
        this.user['free'] = [];

      // this.listprojects = this.user['projects'];
      // this.events = this.user['free'];

      // for (let i = 0; i < this.events.length; i++) {
      //   for (let i2 = 0; i2 < this.listprojects.length; i2++) {

      //     if (this.events[i]['id'] == this.listprojects[i2]['id']) {
      //       this.events[i]['title'] = this.listprojects[i2]['name']

      //       this.events[i]['jobid'] = this.listprojects[i2]['job'];
      //       this.events[i]['color'] = this.getColor(this.listprojects[i2]['job']);
      //     }
      //   }
      // }

      //this.calendarOptions.events = this.chkTech();
    });

    this.dataService.getList("projects").subscribe((data) => {
      this.listprojects = data;

      this.dataService.getList("users").subscribe((data) => {
        this.listusers = data;

        this.listprojects_unread_com = {};
        this.listprojects_unread_cv = {};

        this.listprojects_read_com = {};
        this.listprojects_read_cv = {};

        this.listprojects.forEach(item => {
          this.refreshUserNameCv(item);
          this.refreshUserNameCom(item);
        })

        this.all_unread_cv = 0;
        Object.keys(this.listprojects_unread_cv).forEach(key => {
          this.all_unread_cv = this.all_unread_cv + this.listprojects_unread_cv[key]
        })

        this.all_unread_com = 0;
        Object.keys(this.listprojects_unread_com).forEach(key => {
          this.all_unread_com = this.all_unread_com + this.listprojects_unread_com[key]
        })

        this.setevents();
        this.filterProjects();
      })

    })

    this.dataService.getList("projects_cv").subscribe((data) => {
      let self = this;

      if (data) {
        this.listfiles_user = data.filter(function (entry) {
          return entry['userid'] == self.user['id'];
        })
      }
    });

  }

  refreshUserNameCom(project) {
    this.listusers_name = {};
    if (!project['comments'])
      project['comments'] = {};

    let found_unread = 0, founduser_unread = 0;
    let found_read = 0, founduser_read = 0;

    Object.keys(project['comments']).forEach(key => {
      founduser_unread = 0;
      found_read = found_read + project['comments'][key].length;

      this.listusers.forEach(item2 => {
        if (key == item2['id'])
          this.listusers_name[key] = item2['name'];
      })

      project['comments'][key].forEach(com => {
        if (!com['read']) {
          found_unread = found_unread + 1;
          founduser_unread = founduser_unread + 1;
        }
      })

      if (founduser_unread > 0)
        this.listprojects_unread_com[key] = founduser_unread;

      this.listprojects_read_com[project['id'] + key] = project['comments'][key].length;
    })

    if (found_unread > 0)
      this.listprojects_unread_com[project['id']] = found_unread;

    this.listprojects_read_com[project['id']] = found_read;
  }

  refreshUserNameCv(project) {
    this.listusers_name = {};
    if (!project['cvc'])
      project['cvc'] = {};

    let found_unread = 0, founduser_unread = 0;
    let found_read = 0, founduser_read = 0;

    Object.keys(project['cvc']).forEach(key => {
      founduser_unread = 0;
      found_read = found_read + project['cvc'][key].filter(function (entry) {
        return !entry['deleted'];
      }).length

      this.listusers.forEach(item2 => {
        if (key == item2['id'])
          this.listusers_name[key] = item2['name'];
      })

      project['cvc'][key].forEach(cv => {
        if (!cv['read']) {
          found_unread = found_unread + 1;
          founduser_unread = founduser_unread + 1;
        }
      })

      if (founduser_unread > 0)
        this.listprojects_unread_cv[key] = founduser_unread;

      this.listprojects_read_cv[project['id'] + key] = project['cvc'][key].filter(function (entry) {
        return !entry['deleted'];
      }).length

    })

    if (found_unread > 0)
      this.listprojects_unread_cv[project['id']] = found_unread;

    this.listprojects_read_cv[project['id']] = found_read;
  }

  getColor(job) {

    if (job == 'Angular')
      return '#2165BA'
    else if (job == 'ReactJs')
      return '#3BBEE8'
    else
      return 'gray'
  }

  clickTech() {
    this.calendarOptions.events = this.chkTech();
  }

  chkTech() {

    let result = [];
    let found = false;

    for (let i = 0; i < this.listtech.length; i++) {

      if (this.listtech[i]['checked']) {

        found = true;
        for (let i2 = 0; i2 < this.events.length; i2++) {
          if (this.listtech[i]['name'] == this.events[i2]['jobid'])
            result.push(this.events[i2]);
        }
      }
    }

    if (found)
      return result;
    else
      return this.events;
  }

  onSelectedRows(rows: Array<GuiSelectedRow>): void {
    if (!rows || rows.length == 0)
      return;

    this.selectedRow = rows;
  }

  editproject(id) {
    alert(id);
  }

  handleDateClick(arg) {
    this.arg = arg;
    this.item = { start: arg.dateStr, state: 'add' }

    $("#myModal").modal("show");
    // $(".modal-title").text("");
    // $(".modal-title").text("Add Event at : "+arg.dateStr);
  }

  handleEventClick(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.item = this.events[i];
        this.item['state'] = 'edit';
        this.setlistcvs(this.item['projectid'])
      }
    }

    $("#myModal").modal("show");
    // $(".modal-title").text("Title: "+arg.event.title);
  }

  handleEventDragStart(arg) {
  }

  handleEventDrop(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.events[i]['start'] = arg.event.start;
        this.events[i]['end'] = arg.event.end;

        this.calendarOptions.events = this.chkTech();
      }
    }
  }

  handleEventResizeStart(arg) {
  }

  handleEventResize(arg) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == arg.event.id) {
        this.events[i]['start'] = arg.event.start;
        this.events[i]['end'] = arg.event.end;

        this.calendarOptions.events = this.chkTech();
      }
    }
  }

  addEvent() {

    this.submittedgen = true;

    if (!this.item['projectid'] || !this.item['appid'] || !this.item['start'])
      return;

    for (let i = 0; i < this.listprojects.length; i++) {
      if (this.listprojects[i]['id'] == this.item['id']) {
        this.item['name'] = this.listprojects[i]['name'];

        this.item['jobid'] = this.listprojects[i]['job'];
        this.item['color'] = this.getColor(this.listprojects[i]['job']);
      }
    }

    if (this.item['state'] == 'add') {
      this.events.push({ id: UUID.UUID(), 
      projectid: this.item['projectid'], appid: this.item['appid'], companyid: this.item['appid'].split('###')[0], cvid: this.item['appid'].split('###')[1], 
      title: this.item['name'], start: this.item['start']});
    }
    else {
      for (let i = 0; i < this.events.length; i++) {
        if (this.events[i]['id'] == this.item['id']) {
          this.events[i] = this.item;
        }
      }

    }

    this.saveevents(this.item['projectid'], this.item['appid'].split('###')[0]);
    this.calendarOptions.events = this.chkTech();

    $("#myModal").modal("hide");
  }

  deleteEvent() {

    if (!this.item['id'] || this.item['state'] == 'add')
      return;

    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i]['id'] == this.item['id']) {
        this.events.splice(i, 1);
      }
    }

    this.saveevents(this.item['projectid'], this.item['companyid']);
    this.calendarOptions.events = this.events;

    $("#myModal").modal("hide");
  }

  showAdd() {
    this.project = {};
    $("#cvgeneral").modal();
  }

  showEdit(data) {
    let self = this;

    // if (!this.selectedRow) {
    //   this.message = "Select project!";

    //   setTimeout(() => {
    //     delete self.message;
    //   }, 1000);

    //   return;
    // }

    // this.project = this.selectedRow[0].data;

    this.project = data;
    $("#cvgeneral").modal();
  }
  editCV(data) {
    this.selectedDev = data.id;
    this.tabadmin = 2;
  }

  showCV(data) {
    this.project = data;

    this.refreshUserNameCv(data);

    if (!this.project['cvc'])
      this.project['cvc'] = {};

    if (!this.project['cvc'][this.user['id']])
      this.project['cvc'][this.user['id']] = [];

    if (!this.isRootAdmin) {
      this.currentuser = { id: this.user['id'] }
      this.listfiles = this.project['cvc'][this.user['id']];
    }
    else
      delete this.currentuser;

    $("#cvlist").modal();
  }
  showComments(data) {
    this.project = data;

    this.refreshUserNameCom(data);

    if (!this.project['comments'])
      this.project['comments'] = {};

    if (!this.project['comments'][this.user['id']])
      this.project['comments'][this.user['id']] = [];

    if (!this.isRootAdmin) {
      this.currentuser = { id: this.user['id'] }
      this.listcomments = this.project['comments'][this.user['id']];
    }
    else
      delete this.currentuser;

    $("#messages").modal();
  }
  showCVOwner(data) {
    this.project = data;

    $("#cvlistowner").modal();
  }


  groupCommenrs() {
    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };

    this.groupedByUser = groupBy(this.project['comments'], 'userid');
    this.keys = Object.keys(this.groupedByUser);
  }

  showDelete(data) {
    let self = this;

    // if (!this.selectedRow) {
    //   this.message = "Select project!";

    //   setTimeout(() => {
    //     delete self.message;
    //   }, 1000);

    //   return;
    // }

    if (!confirm('Delete?'))
      return;

    this.selectedRow = [data];
    for (let i = 0; i < this.selectedRow.length; i++) {
      this.deleteproject(this.selectedRow[i]['id']);
    }
  }

  addproject() {
    this.submittedgen = true;

    if (!this.project['name'])
      return;

    this.project['userid'] = this.user['id'];

    if (!this.project['id']) {
      this.dataService.create("projects", this.project).subscribe((data) => {
        //this.listprojects.unshift(this.project);
      });
    }
    else {
      this.dataService.update("projects", this.project).subscribe((data) => {
        // for (let i = 0; i < this.listprojects.length; i++) {
        //   if (this.listprojects[i]['id'] == this.project['id']) {
        //     this.listprojects[i] = this.project;
        //   }
        // }
      });
    }

    document.getElementById('cvgeneralclose').click();
    this.project = {}
  }

  deleteproject(id) {

    // if (!confirm("Delete?"))
    //   return;

    for (let i = 0; i < this.listprojects.length; i++) {
      if (this.listprojects[i]['id'] == id) {
        this.listprojects[i]['userid_deleted'] = this.user['id'];
        this.dataService.create("projects_deletes", this.listprojects[i]).subscribe((data) => {
        });
      }
    }

    this.dataService.delete("projects", id).subscribe((data) => {
      for (let i = 0; i < this.listprojects.length; i++) {
        if (this.listprojects[i]['id'] == id) {
          this.listprojects.splice(i, 1);
        }
      }
    });

  }

  saveevents(projectid, userid) {
    let self = this;
    this.submittedgen = true;

    let project_ = this.listprojects.filter(function (entry) {
      return entry['id'] == projectid;
    })

    if (project_ && project_.length > 0){
      if (!project_[0]['events'])
      project_[0]['events'] = {}
     

      project_[0]['events'][userid] = this.events
      let item_ = {id: projectid, events: project_[0]['events']}      
      this.dataService.update("projects", item_).subscribe((data) => {
        //self.savenotif('send project');
      });
    }
  }

  savefree() {
    let self = this;

    this.user['free'] = this.events;
    this.dataService.update("users", this.user).subscribe((data) => {
      //self.savenotif('send project');
    });
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

  savenotif(content) {
    this.dataService.create("notifications", { id: UUID.UUID(), email: this.user['email'], content: content, date: new Date() }).subscribe((data) => {

    });
  }

  // listprojects{
  //   let result = [];

  //   if (!this.listprojects || !this.listprojects.length || this.listprojects.length == 0)
  //   this.listprojects = [];

  //   for(let i=0; i < this.listprojects.length; i++){
  //     if (!this.listprojects[i]['deleted'])
  //     result.push(this.listprojects[i]);
  //   }

  //   return result;
  // }


  //comments
  addComment() {

    let self = this;
    this.submittedcom = true;

    if (!this.commwrite['name'])
      return;

    this.commwrite['date'] = new Date();
    this.commwrite['userid'] = this.user['id'];
    this.commwrite['draft'] = true;

    if (!this.commwrite['id']) {
      this.commwrite['id'] = Utils.guid();
      this.listcomments.push(this.commwrite);
    }
    else {
      for (let i = 0; i < this.listcomments.length; i++) {
        if (this.listcomments[i]['id'] == this.commwrite['id']) {
          this.listcomments[i] = this.commwrite;
        }
      }

    }

    this.project['comments'][this.user['id']] = this.listcomments;

    this.dataService.update("projects", this.project).subscribe((data) => {
      self.commwrite = {};
      self.savenotif('add comment');
      setTimeout(() => {
        self.msgref = '';
      }, 2000);
    })

    this.submittedcom = false;
    this.commwrite = {};
  }
  deleteComment(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    for (let i = 0; i < this.project['comments'].length; i++) {
      if (this.project['comments'][i]['id'] == data['id']) {
        this.project['comments'].splice(i, 1);
      }
    }

    this.dataService.update("projects", this.project).subscribe((data) => {
    })
  }

  deleteCv(data) {
    let self = this;

    if (!confirm('Delete?'))
      return;

    this.listfiles = this.project['cvc'][this.currentuser.id];

    for (let i = 0; i < this.listfiles.length; i++) {
      if (this.listfiles[i]['id'] == data['id']) {
        this.listfiles[i]['deleted'] = true;
      }
    }

    this.project['cvc'][this.currentuser.id] = this.listfiles;
    this.dataService.update("projects", this.project).subscribe((data) => {
      this.refreshUserNameCv(this.project);
      this.refreshUserNameCom(this.project);
    })
  }

  setUserCv(data) {

    if (!this.project['cvc'])
      this.project['cvc'] = {}

    this.listfiles = this.project['cvc'][data.key];

    this.refresh_unread_cv(this.project, this.project['id'], data.key);

    this.currentuser = {
      id: data.key,
      name: data.value
    }
  }
  setUserCom(data) {

    if (!this.project['comments'])
      this.project['comments'] = {}

    this.listcomments = this.project['comments'][data.key];
    this.refresh_unread_com(this.project, this.project['id'], data.key);

    delete this.listprojects_unread_com[data.key];

    this.currentuser = {
      id: data.key,
      name: data.value
    }
  }

  refresh_unread_cv(project, projectid, userid) {
    if (this.listprojects_unread_cv[projectid] > 0 && this.listprojects_unread_cv[userid] > 0)
      this.listprojects_unread_cv[projectid] = this.listprojects_unread_cv[projectid] - this.listprojects_unread_cv[userid];

    delete this.listprojects_unread_cv[userid];

    if (this.listprojects_unread_cv[projectid] <= 0)
      delete this.listprojects_unread_cv[projectid];

    if (project['cvc'][userid]) {
      project['cvc'][userid].forEach(element => {
        element['read'] = true;
      });
    }

    let project_ = {
      id: projectid,
      cvc: project['cvc']
    }
    this.dataService.update("projects", project_).subscribe((data) => {
    });
  }

  refresh_unread_com(project, projectid, userid) {
    if (this.listprojects_unread_com[projectid] > 0 && this.listprojects_unread_com[userid] > 0)
      this.listprojects_unread_com[projectid] = this.listprojects_unread_com[projectid] - this.listprojects_unread_com[userid];

    delete this.listprojects_unread_com[userid];

    if (this.listprojects_unread_com[projectid] <= 0)
      delete this.listprojects_unread_com[projectid];

    if (project['comments'][userid]) {
      project['comments'][userid].forEach(element => {
        element['read'] = true;
      });
    }

    let project_ = {
      id: projectid,
      comments: project['comments']
    }
    this.dataService.update("projects", project_).subscribe((data) => {
    });
  }


  //upload file
  selectFile(event: any) {
    this.selectedFile = event.target.files;
  }
  uploadFile() {
    let self = this;

    if (!this.selectedFile && this.selectedFile_user) {

      this.message = "waiting ..."

      self.listfiles.push({
        id: self.selectedFile_user['id'],
        file: self.selectedFile_user['file_name'],
        url: self.selectedFile_user['file_url']
      });

      this.project['cvc'][this.user['id']] = self.listfiles;

      this.dataService.update("projects", self.project).subscribe((data) => {
        self.commwrite = {};
        self.savenotif('upload cv');
        setTimeout(() => {
          self.msgref = '';
          delete self.selectedFile_user;
          delete self.message;
        }, 2000);
      })
    }

    if (!this.selectedFile)
      return;

    this.startupload = true;

    let file = <File>this.selectedFile.item(0);
    delete this.selectedFile;

    this.currentItem = { id: Utils.guid(), createdAt: new Date(), name: file.name, userid: this.user['id'] }

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];

    this.message = "waiting ..."
    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload, "projects_cv", this.currentItem, 'projects_cv', ext).subscribe(

      async percentage => {
        this.percentage = Math.round(percentage);

        if (this.percentage == 100) {
          this.currentFileUpload = undefined;

          let nr = 5000;
          while (!self.currentItem['file_url'] && nr >= 0) {
            await new Promise(resolve => setTimeout(() => resolve(), 100));
            nr = nr - 10;
          }

          self.listfiles.push({
            id: self.currentItem['id'],
            createdAt: self.currentItem['createdAt'],
            file: self.currentItem['file_name'],
            url: self.currentItem['file_url']
          }
          );

          this.project['cvc'][this.user['id']] = self.listfiles;

          this.dataService.update("projects", self.project).subscribe((data) => {
            self.commwrite = {};
            self.message = '';
            self.savenotif('upload cv');
            setTimeout(() => {
              self.msgref = '';
            }, 2000);
          })

        }
      },
      error => {
        //console.log(error);
      }
    );
  }
  uploadFileOwner() {
    let self = this;

    this.startupload = true;

    let file = <File>this.selectedFile.item(0);
    delete this.selectedFile;

    this.currentItem = { id: Utils.guid(), createdAt: new Date().toISOString(), name: file.name, userid: this.user['id'] }

    let ext = 'jpg';
    if (file.name.split('.').length > 0)
      ext = file.name.split('.')[file.name.split('.').length - 1];

    this.message = "waiting ..."
    this.currentFileUpload = new FileUpload(file);
    this.uploadService.pushFileToStorage(this.currentFileUpload, "projects_cv_owner", this.currentItem, 'projects_cv_owner', ext).subscribe(

      async percentage => {
        this.percentage = Math.round(percentage);

        if (this.percentage == 100) {
          this.currentFileUpload = undefined;

          let nr = 5000;
          while (!self.currentItem['file_url'] && nr >= 0) {
            await new Promise(resolve => setTimeout(() => resolve(), 100));
            nr = nr - 10;
          }

          if (!self.project['cvc_owner'])
          self.project['cvc_owner'] = [];

          self.project['cvc_owner'].push({
            id: self.currentItem['id'],
            createdAt: self.currentItem['createdAt'],
            file: self.currentItem['file_name'],
            url: self.currentItem['file_url']
          }
          );

          let item_ = {id: self.project['id'], cvc_owner: self.project['cvc_owner']}
          this.dataService.update("projects", item_).subscribe((data) => {
            self.commwrite = {};
            self.message = '';
            self.savenotif('upload cv');
            setTimeout(() => {
              self.msgref = '';
            }, 2000);
          })

        }
      },
      error => {
        //console.log(error);
      }
    );
  }
  deleteFile() {

    // if (!confirm('Delete?'))
    //   return;

    // if (!this.dev['cvdeleted'])
    //   this.dev['cvdeleted'] = [];

    // this.dev['cvdeleted'].push({ cvid: this.dev['cvid'], cvurl: this.dev['cvurl'] })

    // delete this.dev['cvid'];
    // delete this.dev['cvurl'];

    // this.dataService.update("users", this.user).subscribe((data) => {
    // });
  }
  deleteFileOwner() {

    // if (!confirm('Delete?'))
    //   return;

    // if (!this.dev['cvdeleted'])
    //   this.dev['cvdeleted'] = [];

    // this.dev['cvdeleted'].push({ cvid: this.dev['cvid'], cvurl: this.dev['cvurl'] })

    // delete this.dev['cvid'];
    // delete this.dev['cvurl'];

    // this.dataService.update("users", this.user).subscribe((data) => {
    // });
  }
  cancelFile() {
    delete this.selectedFile;
    delete this.selectedFile_user;
  }

  setevents(){
      this.events = [];

      for (let i2 = 0; i2 < this.listprojects.length; i2++) {

        let item_ = this.listprojects[i2];
        if (!item_['events'])
        item_['events'] = {}
       
        Object.keys(item_['events']).forEach(key => {
          if (item_['events'][key])
          this.events = [...this.events, ...item_['events'][key]]
        })

        // if (!item_['events'][this.user['id']])
        // item_['events'][this.user['id']] = []
      }
      this.calendarOptions.events = this.events;
  }


  setlistcvs(projectid){
    this.listcvs = [];

    if (!projectid || projectid == '')
    return;
    
    for (let i2 = 0; i2 < this.listprojects.length; i2++) {

      let item_ = this.listprojects[i2];

      if (item_['id'] == projectid){
        if (!item_['cvc'])
        item_['cvc'] = {}
       
        Object.keys(item_['cvc']).forEach(key => {
          if (item_['cvc'][key]){
            item_['cvc'][key].forEach(element => {
              if (!element['deleted']){
                element['appid'] = key+'###'+element['id']
                this.listcvs.push(element)
              }
            });
          }
        })
  
        // if (!item_['events'][this.user['id']])
        // item_['events'][this.user['id']] = []
      }
    }
}

changeProject(event, item){
  let project_ = this.listprojects.filter(function (entry) {
    return entry['id'] == event.target.value;
  })

  if (project_ && project_.length > 0){
    item['projectid'] = project_[0]['id']
    item['name'] = project_[0]['name']
    this.setlistcvs(item['projectid']);
  }
}

  filterProjects() {
    this.listprojects2 = [];

    if (!this.searchTerm || !this.searchTerm['name'])
      return this.listprojects2 = this.listprojects;

    if (this.isRootAdmin) {
      for (let i = 0; i < this.listprojects.length; i++) {
        if (Utils.contain(this.listprojects[i]['name'], this.searchTerm['name']) ||
          Utils.contain(this.listprojects[i]['description'], this.searchTerm['name']) ||
          Utils.contain(this.listprojects[i]['descriptionadmin'], this.searchTerm['name']) ||
          Utils.contain(this.listprojects[i]['obsadmin'], this.searchTerm['name']))
          this.listprojects2.push(this.listprojects[i]);
      }
    }
    else {
      for (let i = 0; i < this.listprojects.length; i++) {
        if (Utils.contain(this.listprojects[i]['name'], this.searchTerm['name']) ||
          Utils.contain(this.listprojects[i]['description'], this.searchTerm['name']))
          this.listprojects2.push(this.listprojects[i]);
      }
    }
  }


    RegisterWithFCM() {

      try {
  
          if (window.FirebasePlugin == null) {
              alert("FCMPlugin is null")
              return;
          }
  
          window.FirebasePlugin.getToken(function (token) {
              //”token” received from FCM server.
              //It will get automatically registered in you device.No extra code is needed.
              alert(token);
              (<HTMLInputElement>document.getElementById("txtFCMId")).value = token;
          });
  
      }
  
      catch (e) {
          alert(e);
      }
  }
  
  
  
  GetMessageFromGCM() {
  
      alert("onNotificationOpen");
      try {
          window.FirebasePlugin.onNotificationOpen(function (data) {
              alert(JSON.stringify(data));
          });
  
      } catch (e) {
          alert(e);
      }
  
  }

}
