import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare var FirebasePlugin;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

  // let onDeviceReady = () => {
  //   platformBrowserDynamic().bootstrapModule(AppModule);

  //   // FirebasePlugin.onNotificationOpen((notification) => {
  //   //   alert(notification);
  //   // });
  // };
  // let onBackKeyDown = () => {
  //   setTimeout(function(){ (navigator as any).app.exitApp(); }, 200);
  // };
  // document.addEventListener('deviceready', onDeviceReady, false);
  // document.addEventListener("backbutton", onBackKeyDown, false); 