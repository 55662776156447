import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import {UUID} from 'angular2-uuid'; 

import { DataService } from '../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  messageForm: FormGroup;
  submitted: boolean;

  user;username;
  jobs;
  loadingjobs=true;
  bapply=false;btech=false;bmessages=false;
  itemtemp;
  listdel = [];
  loaded;countries;
  bSave=false;
  tabadmin=2;
  error;info;

  submittedkill;skill={};listskills=[];

  hrefAdmins=false;
  hrefCrypto;hrefBytecoin=false;hrefDogecoin=false;
  hrefStarter=false;hrefSkills=false;
  hrefDevs=false;hrefProjects=false;hrefFree=false;hrefTeams=false;
  hrefLearn=false;hrefMentor=false;
  isRootAdmin;isAdmin;

  constructor( 
    private afAuth: AngularFireAuth, 
    public router: Router, 
    private fb: FormBuilder,
    private dataService : DataService) {

   }

  ngOnInit() {

    this.countries = countries['default'];

    this.hrefAdmins = window.location.href.toLowerCase().indexOf('admins.') >=0;
    this.hrefStarter = window.location.href.toLowerCase().indexOf('starter.') >=0;
    this.hrefSkills = window.location.href.toLowerCase().indexOf('skills.') >=0;

    this.hrefBytecoin = window.location.href.toLowerCase().indexOf('bytecoin.') >=0;
    this.hrefDogecoin= window.location.href.toLowerCase().indexOf('dogecoin.') >=0;

    this.hrefDevs= window.location.href.toLowerCase().indexOf('devs.') >=0;
    this.hrefProjects= window.location.href.toLowerCase().indexOf('projects.') >=0;
    this.hrefTeams= window.location.href.toLowerCase().indexOf('teams.') >=0;
    this.hrefFree= window.location.href.toLowerCase().indexOf('free.') >=0;

    this.hrefLearn= window.location.href.toLowerCase().indexOf('learn.') >=0;
    this.hrefMentor= window.location.href.toLowerCase().indexOf('mentor.') >=0;

    if(this.hrefLearn){
      this.router.navigate(['/learn']);
      return;
    }
    if(this.hrefMentor){
      this.router.navigate(['/mentor']);
      return;
    }
    else if(this.hrefProjects){
      this.router.navigate(['/projects']);
      return;
    }
    else if(this.hrefDevs){
      this.router.navigate(['/devslist']);
      return;
    }
    else if(this.hrefFree){
      this.router.navigate(['/courses']);
      return;
    }
    else if(this.hrefTeams){
      this.router.navigate(['/teams']);
      return;
    }    
    else if (this.hrefAdmins){
      this.router.navigate(['/admins']);
      return;
    }
    else if (this.hrefBytecoin){
      this.hrefCrypto = 'Bytecoin';
      this.router.navigate(['/crypto']);
      return;
    }
    else if (this.hrefDogecoin){
      this.hrefCrypto = 'Dogecoin';
      this.router.navigate(['/crypto']);
      return; 
    }
    
    let tmp =localStorage.getItem('currentUser'); 
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined){
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' && 
          this.user['lastname'] && this.user['lastname'] != '')
          this.username = this.user['firstname'] + '' + this.user['lastname'];
      else
      this.username = this.user['email']; 

      this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
      this.isRootAdmin = (this.user['email'] == 'admin@admin.ro') && this.user['type'] && this.user['type'] == 'vvv';
      if (this.isRootAdmin)
      this.isAdmin = true;

      if (this.hrefCrypto){
        this.router.navigate(['/crypto']);
        return;
      }
      else{
        if (this.isRootAdmin) {
          this.router.navigate(['/admins']);
          return;
        }
        else if (this.isAdmin) {
          this.router.navigate(['/projectsadmin']);
          return;
        }
        else {
          this.router.navigate(['/projectslist']);
          return;
        }
      }
    }

    if (!this.user || !this.user['id']){
      this.router.navigate(['/auth/login']);
      return;
    }

  }
  
}
