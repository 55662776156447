import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AngularFireAuth} from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
              private afAuth: AngularFireAuth, ) {}

  async canActivate() {
    const user = await this.afAuth.auth.currentUser;
    const isLoggedIn = !!user;

    let uuu = localStorage.getItem('currentUser');
    if (!uuu || uuu == '' || uuu == undefined || uuu == 'undefined') {
      await this.router.navigate(['/auth/login']);
      return false
    }

    return true;
  }

}
