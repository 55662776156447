import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as countries from 'src/_data/country.json';
import {UUID} from 'angular2-uuid'; 

import { DataService } from '../services/data.service';

@Component({
  selector: 'crypto',
  templateUrl: './crypto.component.html',
  styleUrls: ['./crypto.component.css']
})
export class CryptoComponent implements OnInit {

  messageForm: FormGroup;
  submitted: boolean;

  hrefAdmins;hrefSkills;

  user;username;
  jobs;
  loadingjobs=true;
  bapply=false;btech=false;bmessages=false;
  itemtemp;
  listdel = [];
  loaded;countries;
  bSave=false;
  tabadmin=2;
  error;info;

  submittedkill;skill={};listskills=[];
  hrefCrypto;hrefBytecoin=false;hrefDogecoin=false;
  isRootAdmin;isAdmin;

  constructor( 
    private afAuth: AngularFireAuth, 
    public router: Router, 
    private fb: FormBuilder,
    private dataService : DataService) {

   }

  ngOnInit() {

    this.countries = countries['default'];

    if (this.hrefCrypto)
    this.tabadmin = 3;

    this.submitted = false;
    this.messageForm = this.fb.group({
      message: ['', [Validators.required]]
    });

    let tmp =localStorage.getItem('currentUser'); 
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined){
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' && 
          this.user['lastname'] && this.user['lastname'] != '')
          this.username = this.user['firstname'] + '' + this.user['lastname'];
      else
      this.username = this.user['email']; 

      this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
      this.isRootAdmin = (this.user['email'] == 'admin@admin.ro') && this.user['type'] && this.user['type'] == 'vvv';
      if (this.isRootAdmin)
      this.isAdmin = true;
    }

    if (!this.user || !this.user['id']){
      this.router.navigate(['/auth/login']);
      return;
    }
    
    this.dataService.getByID("users", this.user['id']).subscribe((data)=>{
      this.user = data;

      this.listskills = this.user['skills'];

      if (!this.user['step1'])
      this.user['step1'] = {};

      if (!this.user['step1']['status'])
      this.user['step1']['status'] = 'current';

      if (!this.user['step2'])
      this.user['step2'] = {};

      if (!this.user['step3'])
      this.user['step3'] = {};

      if (!this.user['step4'])
      this.user['step4'] = {};

      if (!this.user['step5'])
      this.user['step5'] = {};

      if (!this.user['step6'])
      this.user['step6'] = {};

      if (!this.user['step7'])
      this.user['step7'] = {};

      // if (!this.user['jobs']) 
      // this.user['jobs'] = []; 

      // if (!this.user['tech']) 
      // this.user['tech'] = {'agree':true}; 

      // if (!this.user['messages']) 
      // this.user['messages'] = []; 

      self.loaded = true;

      
    }); 

    let self = this;
    this.dataService.getList("jobs").subscribe((data)=>{

      // data = data.filter(function (entry) {
      //   return entry['status'] && entry['status'] == 'active';
      // });

      // self.jobs = data;
      // self.loadingjobs = false;
      // self.savenotif('view');
    }); 
    
    this.setdel();

  }

  apply(item) {

    this.bapply = true;
    this.itemtemp = item;

    if (!this.user['jobs']) 
      this.user['jobs'] = []; 

      let temp = <[]>this.user['jobs'].filter(function (entry) {
        return entry['jobid'] && entry['jobid'] == item['id'];
      });

      if (temp && temp.length >0)
      return;
    
      let self = this;
      (<any[]>this.user['jobs']).push({id:UUID.UUID(), jobid:item['id'], createAt: new Date()});

      for(let i2=0; i2<this.user['jobs'].length; i2++){

        if (!this.user['jobs'][i2]['jobid'] || 
             this.user['jobs'][i2]['jobid'] == undefined || 
             this.user['jobs'][i2]['jobid'] == 'undefined')  
             this.user['jobs'][i2]['jobid'] = 0;
      }

    this.dataService.update("users", {id:this.user['id'], jobs:this.user['jobs']}).subscribe((data)=>{
      setTimeout(function(){self.bapply = false;}, 500);
      self.savenotif('apply job');
    }); 

    if (!item['users']) 
    item['users'] = []; 

    item['users'].push({id:UUID.UUID(), userid:this.user['id'], createAt: new Date()});
    this.dataService.update("jobs", item).subscribe((data)=>{

    });  
  }

  isApplied(jobid){
    let result = false;

    if (!this.user['jobs']) 
    this.user['jobs'] = []; 

    let temp = <[]>this.user['jobs'].filter(function (entry) {
      return entry['jobid'] && entry['jobid'] == jobid;
    });

    if (temp && temp.length >0)
    result = true;

    return result;

  }

  savetech(){

    this.btech = true;
    let self = this;

    if (!this.user['tech_list']) 
    this.user['tech_list'] = []; 

    this.user['tech']['id'] = UUID.UUID();
    this.user['tech']['createAt'] = new Date();
    (<any[]>this.user['tech_list']).push(this.user['tech']);


    this.dataService.update("users", {id:this.user['id'], tech:this.user['tech'], tech_list:this.user['tech_list']}).subscribe((data)=>{
      setTimeout(function(){self.btech = false;}, 500);
      self.savenotif('save tech');
    }); 

  }

  savemessage(values){

    if (!this.user['messages']) 
    this.user['messages'] = []; 

    this.submitted = true;

    if (!this.messageForm.valid)
    return;

    this.bmessages = true;
    let self = this;

    (<any[]>this.user['messages']).push({id:UUID.UUID(), message: values.message, createAt: new Date()});

    this.dataService.update("users", {id:this.user['id'], messages:this.user['messages']}).subscribe((data)=>{
      setTimeout(function(){self.bmessages = false;}, 500);
      self.savenotif('save message');
      document.getElementById('step21close').click();
    }); 
  }

  get form() {
    return this.messageForm.controls;
  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }


  savenotif(content){
    if (this.user['email'] === 'maleeshasafdari8@gmail.com')
    return;

    this.dataService.create("notifications", {id:UUID.UUID(), email:this.user['email'], content:content, date: new Date()}).subscribe((data)=>{

    }); 
  }

  setdel(){
    //maleeshasafdari8@gmail.com
    this.dataService.getList("notifications").subscribe((data)=>{
      data = data.filter(function (entry) {
        return entry['email'] && entry['email'] == 'maleeshasafdari8@gmail.com' && entry['content'] === 'view jobs list';
      });

      this.listdel = data;
      //console.log('ddd',data.length);
    }); 
  }
  del(){
    
    for(let i= 0; i < this.listdel.length; i++) {
      this.dataService.delete("notifications", this.listdel[i]['id']).subscribe((data)=>{

      }); 
    }
  }

  saveskills(){

    let self = this;
    this.submitted = true;
    delete this.error;
    delete this.info;

    this.user['skills'] = this.listskills;

    if (!this.user['country']){
      this.error = "Country is required!"
      return;
    }

    let result = [];

    if (!this.listskills)
    this.listskills = [];

    for(let i=0; i < this.listskills.length; i++){
      if (!this.listskills[i]['delete'])
      result.push(this.listskills[i]);
    }


    if (result.length == 0){
      this.error = "Skills are mandatory!"
      return;
    }

    this.bSave = true;

    this.dataService.update("users", this.user).subscribe((data)=>{
      this.submitted = false;
      this.bSave = false;
      this.info = "Saved!";
      self.savenotif('send skills');
    }); 

  } 

  savestep1(){

    let self = this;
    if (!this.user['step1']['contact'] || !this.user['step1']['country'] || !this.user['step1']['domain'])
    return;

    this.user['step1']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step1');
      document.getElementById('step1close').click();
    }); 

  }

  savestep2(){

    let self = this;
    if (!this.user['step2']['details'])
    return;

    this.user['step2']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step2');
      document.getElementById('step2close').click();
    }); 

  }

  savestep3(){

    let self = this;

    this.user['step3']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step3');
      document.getElementById('step3close').click();
    }); 

  }

  savestep4(){

    let self = this;

    this.user['step4']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step4');
      document.getElementById('step4close').click();
    }); 

  }

  savestep5(){
    let self = this;

    this.user['step5']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step5');
      document.getElementById('step5close').click();
    }); 

  }

  savestep6(){
    let self = this;

    this.user['step6']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step6');
      document.getElementById('step6close').click();
    }); 

  }

  savestep7(){

    let self = this;

    this.user['step7']['status'] = 'send';
    this.dataService.update("users", this.user).subscribe((data)=>{
      self.savenotif('send step7');
      document.getElementById('step7close').click();
    }); 

  }

  addskill(){
    this.submittedkill = true;

    if (!this.skill['skill'] || !this.skill['level'])
    return;

    if (!this.listskills || !this.listskills.length || this.listskills.length == 0)
    this.listskills = [];

    this.skill['id'] = this.guid();
    this.listskills.push(this.skill);

    this.skill = {};
    this.submittedkill = false;
  }

  deleteskill(id){
    if (!confirm("Delete?"))
    return;

    if (!this.listskills || !this.listskills.length || this.listskills.length == 0)
    this.listskills = [];

    for(let i=0; i < this.listskills.length; i++){
      if (this.listskills[i]['id'] == id)
      this.listskills[i]['delete'] = true;
    }
  }

  getskills(){
    let result = [];

    if (!this.listskills || !this.listskills.length || this.listskills.length == 0)
    this.listskills = [];

    for(let i=0; i < this.listskills.length; i++){
      if (!this.listskills[i]['delete'])
      result.push(this.listskills[i]);
    }

    return result;
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}
guid() {

    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
        this.s4() + '-' + this.s4() + this.s4() + this.s4();
}

topFunction() {
  this.tabadmin=4;

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

}
