export class Utils {
    static s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    static guid() {
        return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
            this.s4() + '-' + this.s4() + this.s4() + this.s4();
    }
    static contain(obj1, obj2){
        return obj1 && obj2 && obj1.toString().toLowerCase().indexOf(obj2.toString().toLowerCase()) >= 0
    }
}