import {Component, OnInit, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { Utils } from 'src/app/shared/utils';
import { DataService } from '../services/data.service';
declare let $: any;

// import {Base} from '../../_ewo/base';
// import {BaseService} from '../../_ewo/service/service';

@Component({
  selector: 'examdetail',
  styleUrls: ['./examdetail.component.css'],
  templateUrl: './examdetail.component.html'
})
export class ExamdetailComponent implements OnInit{

    user: any = {};
    userinfo: any = {};
    listexams:any[];

    setDevID;setExamID;

    @Output() finish = new EventEmitter();

    @Input()
    set setDev(id: any) {
  
      if (!id) {
        //this.dev={skills:[], experience:[], education:[]};
        return;
      }
  
      this.setDevID = id;
      this.ngOnInit();
    }

    @Input()
    set setExam(id: any) {
  
      if (!id) {
        //this.dev={skills:[], experience:[], education:[]};
        return;
      }
  
      this.setExamID = id;
      this.ngOnInit();
    }

    bExamLink;examid:any;
    item:any;userexam:any;

    currentindex = 0;
    showfinish = false;
    showblock = false;
    listrandom=[]; listquestion=[];

    constructor(
        //private serviceBasel:BaseService, 
        public router: Router,
        private route:ActivatedRoute, 
        private elementRef:ElementRef, 
        private dataService: DataService,
        private ngxSmartModalService: NgxSmartModalService
    ) {
       // super(serviceBasel);
    }

    ngOnInit() {

        // this.entitytypeid = "exams";
        // this.entityapi = "exams";

        // document.getElementById('preloader').style.display = 'block';
        // document.getElementById('preloader2').style.display = 'block';

        if (localStorage.getItem('currentUser')) {
            this.user = JSON.parse( localStorage.getItem('currentUser') );
        }


        this.route.params.subscribe(params => {

        let self = this;

        if (window.location.href.toLowerCase().indexOf('/exam/') >=0 && params['id']){
            this.examid = params['id'];
            let tempSubscription =  this.dataService.getByID("linksexam",this.examid).subscribe(data => {
                
                if (data){
                this.bExamLink = true;
                this.setDevID = data['devid'];
                this.examid = data['examid'];
                this.user.id = data['userid']; 
                this.loadExam();
            }
            else {
              this.router.navigate(['/auth/login']);
              return;
            }
            });
        }
        else{
            this.examid = this.setExamID; //params['id'];
            this.loadExam();
        }

        });
        //super.ngOnInit();
    }


    loadExam(){
        let tempSubscription =  this.dataService.getList("exams").subscribe(data => {

            tempSubscription.unsubscribe();
            this.listexams = data;

            for (var key in data) {
                var item = data[key];
                if (item) {
                    if (item['id'] === this.examid) {
                       this.item = item;

                       if (!this.item['nrmaxexam'] || this.item['nrmaxexam'] == 0){
                           if (!this.item.listcap || this.item.listcap.length == 0)
                            this.item['nrmaxexam'] = 0;
                           else
                            this.item['nrmaxexam'] = this.item.listcap[0].questions.length;
                       }

                       if (this.item['nrmaxexam'] == 0)
                        this.listrandom = [];
                       else if (this.item.listcap && this.item.listcap.length > 0)
                        this.listrandom = this.getrandom(0,  this.item['nrmaxexam'], this.item.listcap[0].questions.length, [])

                        if (this.item['nrmaxexam'] > 0 && this.item['nrmaxexam'] < this.item.listcap[0].questions.length ){
                        this.listquestion = this.getquestions();
                        this.item.listcap[0].questions = this.listquestion;
                        }

                        if (this.item.listcap && this.item.listcap.length > 0 &&
                            this.item.listcap[0].questions && this.item.listcap[0].questions.length > 0) {
                            this.item.listcap[0].questions[0].answered = false;
                            this.item.listcap[0].questions[0].visited = true;
                        }

                       break;
                    }
                }
            }

            let tempSubscription2 =  this.dataService.getList("users").subscribe(data => {

                tempSubscription2.unsubscribe();

                for (var key in data) {
                    var item = data[key];
                    if (item) {
                        if (item['id'] === this.user.id) {
                            this.userinfo = item;

                            if(!this.userinfo.exams)
                                this.userinfo.exams = [];


                            this.userexam = {
                                "id": Utils.guid(),
                                "devid": this.setDevID,
                                "examid": this.item.id,
                                "date": new Date(),
                                "name": this.item.name,
                                "category": this.item.category,
                                "questions":[]
                            }

                            if (this.bExamLink && item['devs']){
                                for(let id=0; id < item['devs'].length; id++){
                                    if (item['devs'][id]['id'] == this.setDevID)
                                        this.userexam['resource'] = item['devs'][id]['name'];
                                }
                            }

                            let found = false;
                            for (var key in this.userinfo.exams) {
                                var item:any = {};
                                item = this.userinfo.exams[key];
                                if (item && item['date']) {
                                    if (item['examid'] && item['examid'] === this.item.id &&
                                        (!item['devid'] || (item['devid'] && item['devid'] === this.setDevID) ) ) {
                                        found = true;

                                        let dt1:any = new Date();
                                        let dt2 = (item['date'] && item['date']['seconds'])?item['date']['seconds'] * 1000: Date.parse(item['date'].toDate());
    
                                        let diffInMs: number = Date.parse(dt1) - dt2;
                                        let diffInMins: number = diffInMs / 1000 / 60;
                                        let diffInDays: number = diffInMs / 1000 / 60 / 60 / 24;
                                        //console.log('diff',diffInDays);

                                        if (diffInDays <= 1) //diffInDays <= 14
                                            this.showblock = true;

                                        break;
                                    }
                                }
                            }

                            if (found)
                            {

                            }

                            this.userinfo.exams.unshift(this.userexam);
                            this.userexam.countquestions = 0;

                            for (var key in this.item.listcap) {
                                var item:any = {};
                                item = this.item.listcap[key];
                                if (item && item['questions']) {

                                    this.userexam.countquestions = this.userexam.countquestions + item['questions'].length;
                                    for (var key2 in item['questions']) {
                                        var item2 = item['questions'][key2];
                                        if (item2) {

                                            for (var key3 in this.userexam.questions) {
                                                var item3 = this.userexam.questions[key3];
                                                if (item3) {
                                                    if (item2.id === item3.id) {

                                                        item2.uservalid = item3.valid;

                                                        if(item2.type == 1 && item3.answers && item3.answers.length > 0) {
                                                            item2.check = item3.answers[0]['id'];
                                                            item2.answered = true;
                                                            item2.visited = true;
                                                        }
                                                        else {

                                                            for (var key21 in item2.answers) {
                                                                var item21 = item2.answers[key21];
                                                                if (item21) {
                                                                    item21.check = false;
                                                                    for (var key31 in item3.answers) {
                                                                        var item31 = item3.answers[key31];
                                                                        if (item31) {
                                                                            if (item31.id === item21.id) {
                                                                                item21.check = true;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                        }

                                                        break;
                                                    }
                                                }
                                            }

                                        }
                                    }

                                }
                            }

                            break;
                        }
                    }
                }

                //this.addscript(this.elementRef.nativeElement, "js/main.js", "mainjs");

                setTimeout(function () {
                    // document.getElementById('preloader').style.display = 'none';
                    // document.getElementById('preloader2').style.display = 'none';
                }, 300);

            });

        });
    }

    saveexam() {

        // document.getElementById('preloader').style.display = 'block';
        // document.getElementById('preloader2').style.display = 'block';

        this.userexam.questions = [];

        for (var key in this.item.listcap) {
            var item = this.item.listcap[key];
            if (item && item.questions) {
                for (var key2 in item.questions) {
                    var item2 = item.questions[key2];
                    if (item2) {

                        let q = {"id":item2.id, "answers":[],"valid":false};

                        if(item2.type == 1 && item2.check && item2.check != '') {
                            q.answers.unshift({"id": item2.check});
                            this.userexam.questions.unshift(q);
                        }
                        else {
                            let found = false;
                            for (var key3 in item2.answers) {
                                var item3 = item2.answers[key3];
                                if (item3) {
                                    if (item3.check && item3.check != false) {
                                        found = true;
                                        q.answers.unshift({"id": item3.id})
                                    }
                                }
                            }

                            if (found)
                                this.userexam.questions.unshift(q);
                        }

                        q.valid = this.verifyquestion(this.item, q);
                    }
                }
            }
        }

        let itemsave_ = {id: this.userinfo['id'], exams: this.userinfo['exams']}

        this.dataService.update("users", itemsave_);
        this.verifyexam(this.userexam);
        this.showfinish = true;

        $("#finishExam").modal("hide");

        let self = this;
        setTimeout(function () {
            // document.getElementById('preloader').style.display = 'none';
            // document.getElementById('preloader2').style.display = 'none';
        }, 300);
    }

    closeFinishExam(){
        $("#finishExam").modal("hide");
        this.finish.emit();
    }

    stopExam(){
        this.finish.emit();
    }

    verifyquestion(exam, question){

        let qvalid = false, found = false;

        if (!this.listexams || this.listexams.length == 0)
            return false;

        for (var keyexam1 in this.listexams) {

            var itemexam1 = this.listexams[keyexam1];
            if (itemexam1) {
                if (itemexam1.id === exam.id) {

                    for (var keyexam2 in itemexam1.listcap) {
                        var itemexam2 = itemexam1.listcap[keyexam2];
                        if (itemexam2) {

                            for (var keyexam3 in itemexam2.questions) {
                                var itemexam3 = itemexam2.questions[keyexam3];
                                if (itemexam3) {

                                    if (itemexam3.id == question.id) {

                                        qvalid = false;

                                        for (var keyexam4 in itemexam3.answers) {
                                            var itemexam4 = itemexam3.answers[keyexam4];
                                            if (itemexam4) {

                                                if (itemexam4['valid'] == true) {

                                                    qvalid = true;
                                                    found = false;

                                                    for (var key4 in question.answers) {

                                                        var item4 = question.answers[key4];
                                                        if (item4) {
                                                            if (item4.id == itemexam4.id)
                                                                found = true;
                                                        }
                                                    }

                                                    if (!found) {
                                                        qvalid = false;
                                                        break;
                                                    }
                                                }
                                            }

                                        }

                                        itemexam3.uservalid = qvalid;
                                        return qvalid;

                                    }
                                }

                            }
                        }

                    }

                    return false;
                }
            }
        }

        return false;
    }

    previousquestion() {

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
            return;

        let q = this.item.listcap[0].questions[this.currentindex];
        this.verifyanswer(q);

        if(this.currentindex > 0) {
            this.currentindex = this.currentindex - 1;
            if (!this.item.listcap[0].questions[this.currentindex].visited || this.item.listcap[0].questions[this.currentindex].visited == false) {
                this.item.listcap[0].questions[this.currentindex].answered = false;
                this.item.listcap[0].questions[this.currentindex].visited = true;
            }
        }
    }

    nextquestion() {

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
        return;

        let q = this.item.listcap[0].questions[this.currentindex];
        this.verifyanswer(q);

        if(this.currentindex < this.item.listcap[0].questions.length - 1) {
            this.currentindex = this.currentindex + 1;
            if (!this.item.listcap[0].questions[this.currentindex].visited || this.item.listcap[0].questions[this.currentindex].visited == false) {
                this.item.listcap[0].questions[this.currentindex].answered = false;
                this.item.listcap[0].questions[this.currentindex].visited = true;
            }
        }
    }

    verifyanswer(q){
        if(q.type == 1 && q.check && q.check != '') {
            q.answered = true;
        }
        else {
            let found = false;
            for (var key3 in q.answers) {
                var item3 = q.answers[key3];
                if (item3) {
                    if (item3.check && item3.check != false) {
                        found = true;
                    }
                }
            }

            if (found)
                q.answered = true;
        }
    }

    selectquestion(index) {
        this.currentindex = index;

        let q = this.item.listcap[0].questions[this.currentindex];
        this.verifyanswer(q);

        if(this.currentindex >0 && (!q.visited || q.visited == false)) {
            this.item.listcap[0].questions[this.currentindex].answered = false;
            this.item.listcap[0].questions[this.currentindex].visited = true;
        }
    }

    getStatusQuestion(index){
        let result = 'not-visited';

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
            return result;

        if (this.item.listcap[0].questions[index].answered == false)
            result = 'not-answered';

        if (this.item.listcap[0].questions[index].answered == true)
            result = 'answered';

        if (this.currentindex == index)
            result = result + ' currentbox';

        return result;
    }

    getNonVisited(){
        let result = 0;

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
            return result;

        for(var i in this.item.listcap[0].questions) {
            let q = this.item.listcap[0].questions[i];
            if (q && !q['visited']){
                result = result +1;
            }
        }

        return result;
    }

    getNonAnswer(){
        let result = 0;

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
            return result;

        for(var i in this.item.listcap[0].questions) {
            let q = this.item.listcap[0].questions[i];
            if (q && q['answered'] == false){
                result = result +1;
            }
        }

        return result;
    }

    getAnswer(){
        let result = 0;

        if (!this.item.listcap || this.item.listcap.length == 0 || !this.item.listcap[0].questions)
            return result;

        for(var i in this.item.listcap[0].questions) {
            let q = this.item.listcap[0].questions[i];
            if (q && q['answered'] == true){
                result = result +1;
            }
        }

        return result;
    }

    verifyexam(exam){

        exam.countquestions2 = 0;

        for (var key3 in exam.questions) {

            var item3 = exam.questions[key3];
            if (item3) {

                if (item3.valid == true)
                    exam.countquestions2 = exam.countquestions2 + 1;
            }
        }
    }

    clearanswer(){

        for (var key in this.item.listcap[0].questions) {
            var item = this.item.listcap[0].questions[key];
            if (item) {

                if (parseInt(key) == this.currentindex) {
                    item.answered = false;

                    if(item.type == 1) {
                        item.check = '';
                    }
                    else {
                        let found = false;
                        for (var key12 in item.answers) {
                            var item12 = item.answers[key12];
                            if (item12) {
                                item12.check = false;
                            }
                        }
                    }

                    for (var key2 in this.userexam.questions) {
                        var item2 = this.userexam.questions[key2];
                        if (item2) {
                            if (item2.id == item.id) {
                                this.userexam.questions.splice(this.findSelectedItem(this.userexam.questions, item2), 1);
                            }
                        }
                    }
                }
            }
        }

    }

    findSelectedItem(source, _item, id='id'):number {
        for (var key in source) {
            var item = source[key];
            if (item) {
                if (item[id] == _item[id])
                    return parseInt(key);
            }
        }

        return -1;
    }

    getquestions(){

        let result = [];

        this.listrandom.sort((a, b) => a - b); 
        
        for (let k=0; k<this.listrandom.length;k++) {
                result.push(this.item.listcap[0].questions[this.listrandom[k]]);
        }

        return result;
    }

    getrandom(nr0, nr, max, result){
            var randomNumber = this.rand(0, max - 1);
            if (result.indexOf(randomNumber) === -1){
                result.push(randomNumber);
                nr0 = nr0 + 1;
                if (nr == nr0)
                    return result;
                else 
                    return this.getrandom(nr0, nr, max, result);    
            }
            else {
                return this.getrandom(nr0, nr, max, result);    
            }
    }

    rand(min, max) {
        var offset = min;
        var range = (max - min) + 1;
      
        var randomNumber = Math.floor( Math.random() * range) + offset;
        return randomNumber;
      }
}
