import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { JwSocialButtonsModule } from 'jw-angular-social-buttons';
import { GuiGridModule } from '@generic-ui/ngx-grid';

import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireAuthModule} from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxSmartModalModule } from 'ngx-smart-modal';

import { FullCalendarModule } from '@fullcalendar/angular'; 
//import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import dayGridPlugin  from '@fullcalendar/daygrid';
import timeGridWeek  from '@fullcalendar/timegrid'; 
import timeGridDay  from '@fullcalendar/timegrid';
import listPlugin  from '@fullcalendar/list'; 
import interactionPlugin from '@fullcalendar/interaction'; 

import {ToggleButtonComponent} from './controls/toggle-button.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HomeComponent} from './home/home.component';
import {CryptoComponent} from './crypto/crypto.component';
import {SkillsComponent} from './skills/skills.component';
import {RootComponent} from './root/root.component';
import {CollaborationComponent} from './collaboration/collaboration.component';
import {TeamComponent} from './team/team.component';
import {MentorComponent} from './mentor/mentor.component';
import {LearnComponent} from './learn/learn.component';
import {TimelineComponent} from './learn/timeline/timeline.component';
import {ProjectsAdminComponent} from './projects_admin/projects_admin.component';
import {ProjectsuserComponent} from './projectsuser/projectsuser.component';
import {ProjectsOwnerComponent} from './projectsowner/projectsowner.component';
import {CvsAdminComponent} from './cvs_admin/cvs_admin.component';
import {CvsuserComponent} from './cvsuser/cvsuser.component';
import {CoursesComponent} from './courses/courses.component';
import {CoursedetailComponent} from './coursedetail/coursedetail.component';
import {ExamdetailComponent} from './examdetail/examdetail.component';
import {ExverifComponent} from './exverif/exverif.component';
import {CvComponent} from './cv/cv.component';
import {CvprojectComponent} from './cvproject/cvproject.component';
import {GdprComponent} from './gdpr/gdpr.component';
import {MenuComponent} from './menu/menu.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';

import { QuillModule } from 'ngx-quill'

import { OrderModule } from 'ngx-order-pipe';
import { FilterPipeModule } from 'ngx-filter-pipe';

import { PipesModule } from "./pipes/pipes.module";

import { DataService } from './services/data.service';

FullCalendarModule.registerPlugins([ 
  dayGridPlugin, timeGridWeek, timeGridDay, listPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    ToggleButtonComponent,
    DashboardComponent,
    HomeComponent,
    CryptoComponent,
    SkillsComponent,
    RootComponent,
    CollaborationComponent,
    TeamComponent,
    MentorComponent,
    LearnComponent,
    TimelineComponent,
    ProjectsAdminComponent,
    ProjectsuserComponent,
    ProjectsOwnerComponent,
    CvsAdminComponent,
    CvsuserComponent,
    CoursesComponent,
    CoursedetailComponent,
    ExamdetailComponent,
    ExverifComponent,
    CvComponent,
    CvprojectComponent,
    GdprComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    JwBootstrapSwitchNg2Module,
    FullCalendarModule,
    NgxSmartModalModule.forRoot(),
    //NgxFullCalendarModule,
    AppRoutingModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    FormsModule,
    ReactiveFormsModule,
    JwSocialButtonsModule,
    OrderModule,
    FilterPipeModule,
    HttpModule,
    QuillModule.forRoot(),
    GuiGridModule,
    PipesModule,
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [DataService],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
