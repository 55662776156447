import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {UUID} from 'angular2-uuid';

import { DataService } from '../services/data.service';

@Component({
  selector: 'menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  isRootAdmin; isAdmin;
  allusers; user; username;

  constructor(
    private afAuth: AngularFireAuth,
    public router: Router)  {}

  ngOnInit() { 

    let tmp = localStorage.getItem('currentUser');
    if (tmp && tmp != '' && tmp != 'undefined' && tmp != undefined) {
      this.user = JSON.parse(tmp);
      if (this.user && this.user['firstname'] && this.user['firstname'] != '' &&
        this.user['lastname'] && this.user['lastname'] != '')
        this.username = this.user['firstname'] + '' + this.user['lastname'];
      else if (this.user && this.user['name'] && this.user['name'] != '')
        this.username = this.user['name'];
      else
        this.username = this.user['email'];

      this.isRootAdmin = this.user['email'] == 'admin@admin.ro' && this.user['type'] && this.user['type'] == 'vvv';
      this.isAdmin = (this.user['email'] == 'dantor99@grr.la' || this.user['email'] == 'ewo@it.it' || this.user['email'] == 'dancosolutions@gmail.com') && this.user['type'] && this.user['type'] == 'admin';
      if (this.isRootAdmin)
      this.isAdmin = true;
    }

  }

  logout() {
    this.afAuth.auth.signOut();
    localStorage.removeItem('currentUser');
    this.router.navigate(['/auth/login']);
  }

}
