import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import {AngularFirestoreDocument, AngularFirestore} from '@angular/fire/firestore';

import { FileUpload } from '../models/fileupload';
import { Observable, from as observableFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  private basePath = '/uploads';

  constructor(private db: AngularFireDatabase, private storage: AngularFireStorage,
              private afStore: AngularFirestore) { }

  pushFileToStorage(fileUpload: FileUpload, folder, item, table, ext): Observable<number> {
    const filePath = `${this.basePath}/${folder}/${item['id']}.${ext}`;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, fileUpload.file);

    uploadTask.snapshotChanges().pipe(
      finalize(async () => {
        await storageRef.getDownloadURL().subscribe(downloadURL => {
          console.log('File available at');
          fileUpload.url = downloadURL;
          fileUpload.name = fileUpload.file.name;
          return this.saveFileData(fileUpload, item, table);
        });
      })
    ).subscribe();

    return uploadTask.percentageChanges();
  }

  private saveFileData(fileUpload: FileUpload, item, table) {

    item['file_name'] = fileUpload['name'];
    item['file_url'] = fileUpload['url'];

    if (!item['id'] )
    item['id'] = this.guid();

    const itemRef: AngularFirestoreDocument<any> = this.afStore.doc(`${table}/${item['id']}`);
    itemRef.set(item, {merge: true});

    return item;
  }

  getFileUploads(numberItems): AngularFireList<FileUpload> {
    return this.db.list(this.basePath, ref =>
      ref.limitToLast(numberItems));
  }

  deleteFileUpload(fileUpload: FileUpload) {
    this.deleteFileDatabase(fileUpload.key)
      .then(() => {
        this.deleteFileStorage(fileUpload.name);
      })
      .catch(error => console.log(error));
  }

  private deleteFileDatabase(key: string) {
    return this.db.list(this.basePath).remove(key);
  }

  private deleteFileStorage(name: string) {
    const storageRef = this.storage.ref(this.basePath);
    storageRef.child(name).delete();
  }

  s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  guid() {

    return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
      this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
}
