import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {UUID} from 'angular2-uuid';

import { DataService } from '../../services/data.service';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Output() details = new EventEmitter()

  constructor()  { }

  ngOnInit() { }

  coursedetail(id){
    if (document.getElementById(id))
    document.getElementById(id).click()
  }

}
