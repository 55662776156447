import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './auth/login/login.component';
import {SignupComponent} from './auth/signup/signup.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {HomeComponent} from './home/home.component';
import {CryptoComponent} from './crypto/crypto.component';
import {SkillsComponent} from './skills/skills.component';
import {RootComponent} from './root/root.component';
import {CollaborationComponent} from './collaboration/collaboration.component';
import {TeamComponent} from './team/team.component';
import {MentorComponent} from './mentor/mentor.component';
import {LearnComponent} from './learn/learn.component';
import {ProjectsAdminComponent} from './projects_admin/projects_admin.component';
import {ProjectsuserComponent} from './projectsuser/projectsuser.component';
import {ProjectsOwnerComponent} from './projectsowner/projectsowner.component';
import {CvsAdminComponent} from './cvs_admin/cvs_admin.component';
import {CvsuserComponent} from './cvsuser/cvsuser.component';
import {CoursesComponent} from './courses/courses.component';
import {CoursedetailComponent} from './coursedetail/coursedetail.component';
import {ExamdetailComponent} from './examdetail/examdetail.component';
import {ExverifComponent} from './exverif/exverif.component';
import {GdprComponent} from './gdpr/gdpr.component';
import {AuthGuard} from './shared/auth.guard';
import {CvComponent} from './cv/cv.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'admins',
    component: RootComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'crypto',
    component: CryptoComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'devslist',
    component: CollaborationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'teams',
    component: TeamComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mentor',
    component: MentorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'learn',
    component: LearnComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'learn/:id',
    component: LearnComponent
  },
  {
    path: 'cv/:id',
    component: CvComponent
  },
  {
    path: 'projectsadmin',
    component: ProjectsAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projectslist',
    component: ProjectsuserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects',
    component: ProjectsOwnerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cvsadmin',
    component: CvsAdminComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cvs',
    component: CvsuserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'partnerships',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'courses',
    component: CoursesComponent
  },
  { path: 'coursedetail/:id', 
    component: CoursedetailComponent, 
    canActivate: [AuthGuard]  
  },
  {
    path: 'skills',
    component: SkillsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'exam/:id',
    component: ExamdetailComponent
  },
  {
    path: 'examverify',
    component: ExverifComponent
  },
  {
    path: 'gdpr',
    component: GdprComponent
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
